import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification, signOut, GoogleAuthProvider, signInWithPopup, fetchSignInMethodsForEmail, signInWithEmailAndPassword, linkWithCredential } from 'firebase/auth';
import { auth } from '../firebase';
import '../css/popup.css'
import google from '../asset/googleicon.svg'

function SignUp({ setIsLoginVisible, setIsSignUpVisible }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // 로딩 상태 추가
    const navigate = useNavigate();


      // 비밀번호 유효성 검사 함수
  const validatePassword = (password) => {
    const minLength = 6;
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*]).{6,}$/;

    return password.length >= minLength && regex.test(password);
  };
    

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate("/");
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        const methods = await fetchSignInMethodsForEmail(auth, error.email);
        if (methods[0] === "password") {
          const userPassword = prompt("이 계정의 비밀번호를 입력하세요:");
          const credential = GoogleAuthProvider.credential(null, error.email);
          
          signInWithEmailAndPassword(auth, error.email, userPassword)
            .then((result) => {
              return result.user.linkWithCredential(credential);
            })
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              setError("계정 연결 실패");
            });
        }
      } else {
        setError("Google 로그인 실패");
        //console.log(error)
      }
    }
  };


  
    const handleSignUp = async (e) => {
      e.preventDefault();
      setError('');
      setLoading(true); // 로딩 시작

      if (!validatePassword(password)) {
        setError('비밀번호는 최소 6자 이상이며, 영문, 숫자, 특수문자를 포함해야 합니다.');
        setLoading(false);
        return;
      }
  
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(userCredential.user);
        await signOut(auth); // 로그아웃
        alert('인증 이메일을 발송했습니다. 이메일을 확인해주세요.');
        //navigate('/'); // 로그인 페이지로 리디렉션
        window.location.reload();
      } catch (error) {
        console.log(error); // 오류 디버깅을 위해 콘솔에 출력
        if (error.code === 'auth/email-already-in-use') {
          setError('이미 존재하는 이메일입니다.');
        } else {
          setError('회원가입 중 오류가 발생했습니다.');
        }
      } finally {
        setLoading(false); // 로딩 종료
      }
    };
  
    return (
      <div class="flex flex-col items-center">
      <div style={{width:'340px'}}>
      <button class="google-button" onClick={handleGoogleSignIn} style={{width:'92%', margin:'0 auto', justifyContent:'center', fontWeight:600}}>
      <img src={google} alt="Google Logo" class="google-logo"/>
      Continue with Google</button>
      </div>

      <div class="divider">or</div>
      <form onSubmit={handleSignUp} style={{width:'340px'}}>
      <div style={{textAlign:'center'}}>
          <input
            type="email"
            placeholder="Email" class="email-input"
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          </div>
          <div style={{textAlign:'center'}}>
          <input
            type="password"
            placeholder="Password" class="password-input"
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />
          </div>
          <div>
          <button class="login-button" type="submit" disabled={loading} style={{ width:'92%', margin:'0 auto', justifyContent:'center', fontWeight:600, padding:'10px 0', marginTop:'20px'}}>
          {loading ? 'ongoing..' : 'Create account'}
          </button>
          <div style={{textAlign:'center'}}>{error && <p>{error}</p>}</div>
          </div>
        </form>
        <div class="additional-options" style={{fontWeight:600}}>
        <span class="account-option" style={{fontWeight:600, fontSize:'15px'}}>
        이미 계정이 있으신가요? <a href="#" class="create-account-link" onClick={()=>{{setIsLoginVisible(true); setIsSignUpVisible(false)}}}>로그인</a>
       </span>
       </div>
      </div>
    );
  }

export default SignUp;
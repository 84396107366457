import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import NavMenuSub from "./NavMenuSub";
import '../css/translation.css';
import '../css/navmenu.css';
import footlogo from '../asset/footer_logo.svg';;


function Translation() {
    const transTwoRef = useRef(null);
    const transFourRef = useRef(null);
    const transFiveRef = useRef(null);
    const transSixRef = useRef(null);

    const [isBottom, setIsBottom] = useState(false);

    const handleScroll = () => {

            // 사용자가 페이지 하단에 스크롤했는지 확인
            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
              setIsBottom(true);
          } else {
              setIsBottom(false);
          }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


//미들메뉴 선택이동
    const scrollToSection = (ref) => {
      if (ref.current) {
          const offsetTop = ref.current.offsetTop-168;
          window.scrollTo({
              top: offsetTop,
              behavior: 'smooth'
          });
      }
  };
  

  const [isSticky, setSticky] = useState('off');
  const goRef = useRef(null); // 'header-middle' 요소에 대한 ref
  const backRef = useRef(null); // 'header-middle' 요소에 대한 ref

  useEffect(() => {
      const handleScroll = () => {
          const go = goRef.current;
          const back = backRef.current;
          
          if (go.getBoundingClientRect().top <= 0) {
              setSticky('on');
          } else if (back.getBoundingClientRect().top > 0) {
              setSticky('off');
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);



    
  return (
    <>
    <NavMenuSub />
    <div className="trans-one">
    <div className="trans-banner">

    </div>
    </div>


        <div ref={goRef} className='go'></div>

        <div className='header-middle-emty'>
        <div className={`${isSticky === 'on' ? 'fixed-middlemenu' : 'header-middle'}`}>
            <div 
            className="mheader-fixed">

                <div className='theader-one' onClick={() => scrollToSection(transTwoRef)}></div>
                <div className='theader-two' onClick={() => scrollToSection(transFourRef)}></div>
                <div className='theader-three' onClick={() => scrollToSection(transFiveRef)}></div>
                <div className='theader-four' onClick={() => scrollToSection(transSixRef)}></div>

            
            </div>
            </div>
        </div>


    <div className={`fixed-middlebtn ${isBottom ? 'at-bottom' : ''}`}>
        <div className='fixed-btn'>
            <Link to="/translationform">
            <div className='midbtn'><div>번역팩 접수하기</div></div>
            </Link>
        </div>
    </div>

    <div ref={backRef} className='back'></div>

        <div ref={transTwoRef} className="trans-two">
            <div className="trans-info">
              <div className='trans-info-top'></div>
              <div className='trans-info-middle'></div>
              <div className='trans-info-bottom'></div>
            </div>
        </div>

        <div className="trans-three">
            <div className="trans-three-info">

            </div>
        </div>

        <div ref={transFourRef} className="trans-four">
            <div className="trans-four-info">
              <div className='trans-four-top'></div>
              <div className='trans-four-bottom'></div>
            </div>
        </div>

        <div ref={transFiveRef} className="trans-five">
            <div className="trans-five-info">

            </div>
        </div>

        <div ref={transSixRef} className="trans-six">
            <div className="trans-six-info">

            </div>
        </div>



        <div className="home-nine">
        <div className="home-footer">
                  <div className="footer-wrap">
          
                    <div className="footer-right">
                    <img src={footlogo}/>
                    </div>

                    <div className="footer-left">

                            <div className="footer-left-txt">

                            <div className="left-txt-top">
                                                <Link to='/term'>
                                                  이용약관
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
                                                  <Link to='/personal'>
                                                  개인정보취급방침
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                                  개인정보의 수집 및 이용목적
                                                </div>

                                <div className="left-txt-middle">
                                상호:(주)빅파이씨앤티 | 사업자 등록번호 : 169-81-00496 | 팩스번호 : 032-714-3850<br/>
                                  경기도 부천시 조마루로 385번길 122 삼보테크노타워 2702-3호 (주)빅파이씨앤티<br/>
                                  대표이사: 이중엽 | 통신판매 신고번호 : 제 2021-경기부천-1209호 | 개인정보보호책임자 : 김두영 | 메일: bigpie_art@jungdari.com<br/>
                                </div>

                                <div className="left-txt-bottom">
                                Copyrights2023ⓒBigpieC&amp;T. All Rights Reserved
                                </div>

                            </div>

                </div>
                </div>
          </div>
          </div>
        

    </>
  )
}

export default Translation;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCNFKtcFwbr7BrTaYR91Xi-SuNkY7Ms3wE",
  authDomain: "darlipage.firebaseapp.com",
  projectId: "darlipage",
  storageBucket: "darlipage.appspot.com",
  messagingSenderId: "25713481042",
  appId: "1:25713481042:web:c5dbd36a87f5110b5f968b"
};


// Firebase 앱 초기화
const app = initializeApp(firebaseConfig);

// 인증 객체 가져오기
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
import { useState, useEffect } from "react";

import { firestore, storage } from "../firebase.js";
import { collection, doc, addDoc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { v4 as uuidv4 } from 'uuid';
import emailjs from '@emailjs/browser';

import NavMenuSub from "./NavMenuSub";
import '../css/translationForm.css';
import '../css/templateForm.css';
import '../css/fileModal.css';
import Agreebox from "./components/Agreebox";
import footlogo from '../asset/footer_logo.svg';
import logo from '../asset/resultlogo.png';
import temCon from "../asset/icon_02.svg"
import { Link } from "react-router-dom";
import PortfolioTitle from "./components/PortfolioTitle.js";



function TranslationForm() {

    //견적용 날짜 포매팅
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}년 ${month}월 ${day}일`;
    };

  const [agree, setAgree] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [name, setName] = useState('');
  const [bizName, setBizName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [webAddress, setWebAddress] = useState('');
  const [businessN, setBusinessN] = useState('');

  const [transType, setTransType] = useState('');

  const [one, setOne] = useState(null); //추가파일
  const [two, setTwo] = useState(null); //추가파일
  const [three, setThree] = useState(null); //추가파일
  const [four, setFour] = useState(null); //추가파일
  const [five, setFive] = useState(null); //추가파일
  const [six, setSix] = useState(null); //gif영역파일
  const [seven, setSeven] = useState(null); //gif영역파일
  const [eight, setEight] = useState(null); //gif영역파일
  const [nine, setNine] = useState(null); //gif영역파일
  const [ten, setTen] = useState(null); //gif영역파일

  const [result, setResult] = useState(null); //견적서 pdf
  const [oneFileName, setOneFileName] = useState(""); //첨부된 파일이름
  const [twoFileName, setTwoFileName] = useState(""); //첨부된 파일이름
  const [threeFileName, setThreeFileName] = useState(""); //첨부된 파일이름
  const [fourFileName, setFourFileName] = useState(""); //첨부된 파일이름
  const [fiveFileName, setFiveFileName] = useState(""); //첨부된 파일이름
  const [sixFileName, setSixFileName] = useState(""); //첨부된 gif영역파일이름
  const [sevenFileName, setSevenFileName] = useState(""); //첨부된 gif영역파일이름
  const [eightFileName, setEightFileName] = useState(""); //첨부된 gif영역파일이름
  const [nineFileName, setNineFileName] = useState(""); //첨부된 gif영역파일이름
  const [tenFileName, setTenFileName] = useState(""); //첨부된 gif영역파일이름

  const [prdUrl, setPrdUrl] = useState('');
  const [prdInfo, setPrdInfo] = useState('');
  const [note, setNote] = useState('');
  const [gifNote, setGifNote] = useState('');
  
  const [page, setPage] = useState(0);
  let timeoutId;
  const [date, setDate] = useState(''); //견적날짜
  const [fee, setFee] = useState("");
  const [loading, setLoading] = useState(false)



          //선택생상강조
          const handleClickA = (buttonId) => {
            setTransType(buttonId);
        };
        //


  // 컴포넌트가 마운트될 때 현재 날짜 설정
  useEffect(() => {
    setDate(formatDate(new Date()));
  }, []);

  const onExtraFileChange = (setter, fileNameSetter) => (event) => {
    const file = event.target.files[0];
    if (file) {
      setter(file); fileNameSetter(file.name);
    }
  };

    //값에 콤마
    const valueComma = (feeNum) => {
    return feeNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


         //추가파일초기화
         const clearOneFile = () => {
          setOne(null);
          setOneFileName("");
        }
        const clearTwoFile = () => {
          setTwo(null);
          setTwoFileName("");
        }
        const clearThreeFile = () => {
          setThree(null);
          setThreeFileName("");
        }
        const clearFourFile = () => {
          setFour(null);
          setFourFileName("");
        }
        const clearFiveFile = () => {
          setFive(null);
          setFiveFileName("");
        }

        const clearSixFile = () => {
          setSix(null);
          setSixFileName("");
        }
        const clearSevenFile = () => {
          setSeven(null);
          setSevenFileName("");
        }
        const clearEightFile = () => {
          setEight(null);
          setEightFileName("");
        }
        const clearNineFile = () => {
          setNine(null);
          setNineFileName("");
        }
        const clearTenFile = () => {
          setTen(null);
          setTenFileName("");
        }

  //서브밋

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, []);


        //핸드폰
        const handlePhoneChange = (e) => {
          const phoneNum = e.target.value;
          setPhone(phoneNum
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
            .replace(/(\-{1,2})$/g, "")
          );
        }
        function handleNextButtonClicks() {
          if(!transType){
            alert('원하시는 타입을 선택해주세요')
            return;
          }
          setPage(currPage => currPage + 1);
          window.scrollTo({ top: 0 });
      }

        function handleNextButtonClick() {
          if (!agree || !personal) {
            alert("유의사항에 동의해주세요");
            return;
            } 
            if (!name || !phone || !email ) {
            alert("필수 항목을 모두 입력해주세요");
            return;
            } 
            setPage(currPage => currPage + 1);
            window.scrollTo({ top: 0 });
        }
  
  
      function handlePrevButtonClick() {
          setPage(currPage => currPage - 1);
          window.scrollTo({ top: 0 });
      }





      
                  const onSubmit = async (event) => {
                    event.preventDefault();
                  
                    if (!agree || !personal) {
                      alert("유의사항에 동의해주세요");
                      return;
                    } 
                    if (!name || !phone || !email || !prdInfo) {
                      alert("필수 항목을 모두 입력해주세요");
                      return;
                    } 
                  
                    alert("완료되었습니다");
                    const uniqueFolder = uuidv4();
                  
                    try {
                      setLoading(true);

                  
                      const docRef = doc(firestore, 'setting', '7aurT01JZnOCV4J0rwux');
                      const docSnap = await getDoc(docRef);
                  
                      if (docSnap.exists()) {
                        if(transType==="A타입"){
                        const newFee = docSnap.data().translationFeeA;
                        setFee(newFee);
                        }else{
                          const newFee = docSnap.data().translationFeeB;
                          setFee(newFee);
                        }
                  
                        setTimeout(async () => {
                          const canvasElement = await html2canvas(document.querySelector('.canvas'));
                          const imageData = canvasElement.toDataURL('image/png');
                          const imgWidth = 210;
                          const pageHeight = 295;
                          const imgHeight = (canvasElement.height * imgWidth) / canvasElement.width;
                          let heightLeft = imgHeight;
                  
                          const pdfDoc = new jsPDF('p', 'mm', 'a4', true);
                          let position = 0;
                  
                          pdfDoc.addImage(imageData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                          heightLeft -= pageHeight;
                  
                          while (heightLeft >= 0) {
                            position = heightLeft - imgHeight;
                            pdfDoc.addPage();
                            pdfDoc.addImage(imageData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                            heightLeft -= pageHeight;
                          }
                  
                          const pdfBlob = pdfDoc.output('blob');


                  
            const metadata = {
              //contentType: 'file/mime-type', // 파일 MIME 타입 설정
              contentType: 'application/pdf',
              //contentDisposition: `attachment; filename="중달이 인증대행 견적서_${authName}님_${date}.pdf"`, // 다운로드되도록 설정
              contentDisposition: `inline; filename="달리페이지 번역팩 견적서_${name}님_${date}.pdf"`,
            };

                  // Firebase Storage에 PDF 업로드
                  const pdfFileRef = ref(storage, `translation/unknown/${date}/${uniqueFolder}/results/result.pdf`);
                  await uploadBytes(pdfFileRef, pdfBlob, metadata);
                  const uploadedPdfFileURL = await getDownloadURL(pdfFileRef);
                  setResult(uploadedPdfFileURL); // 상태 업데이트



              const uploadFile = async (file, folder) => {
                if (!file) return null;
                const fileRef = ref(storage, `translation/unknown/${date}/${uniqueFolder}/${folder}/${file.name}`);
                await uploadBytes(fileRef, file);
                return getDownloadURL(fileRef);
              };

              // Extra 파일 업로드
              const oneURL = await uploadFile(one, 'translationFileOne');
              const twoURL = await uploadFile(two, 'translationFileTwo');
              const threeURL = await uploadFile(three, 'translationFileThree');
              const fourURL = await uploadFile(four, 'translationFileFour');
              const fiveURL = await uploadFile(five, 'translationFileFive');

              const sixURL = await uploadFile(six, 'translationFileSix');
              const sevenURL = await uploadFile(seven, 'translationFileSeven');
              const eightURL = await uploadFile(eight, 'translationFileEight');
              const nineURL = await uploadFile(nine, 'translationFileNine');
              const tenURL = await uploadFile(ten, 'translationFileTen');

              // Firestore에 데이터 저장
              await addDoc(collection(firestore, "translation"), {
                agree: agree,
                personal: personal,
                name: name,
                bizName: bizName,
                phone: phone,
                email: email,
                webAddress: webAddress,
                businessN: businessN,
                prdUrl: prdUrl,
                prdInfo: prdInfo,
                note: note,
                gifNote:gifNote,
                one: oneURL,
                two: twoURL,
                three: threeURL,
                four: fourURL,
                five: fiveURL,
                six: sixURL,
                seven: sevenURL,
                eight: eightURL,
                nine: nineURL,
                ten: tenURL,
                oneFileName: oneFileName,
                twoFileName: twoFileName,
                threeFileName: threeFileName,
                fourFileName: fourFileName,
                fiveFileName: fiveFileName,
                sixFileName: sixFileName,
                sevenFileName: sevenFileName,
                eightFileName: eightFileName,
                nineFileName: nineFileName,
                tenFileName: tenFileName,
                result: uploadedPdfFileURL,
                results: '',
                type:"번역팩",
                transType:transType,
                manager: "매니저 배정중",
                state: "제작요청",
                timestamp: new Date(),
              });

              const templateParams = {
                agree: agree,
                personal: personal,
                name: name,
                bizName: bizName,
                phone: phone,
                email: email,
                webAddress: webAddress,
                businessN: businessN,
                prdUrl: prdUrl,
                prdInfo: prdInfo,
                note: note,
                gifNote:gifNote,
                one: oneURL,
                two: twoURL,
                three: threeURL,
                four: fourURL,
                five: fiveURL,
                six: sixURL,
                seven: sevenURL,
                eight: eightURL,
                nine: nineURL,
                ten: tenURL,
                oneFileName: oneFileName,
                twoFileName: twoFileName,
                threeFileName: threeFileName,
                fourFileName: fourFileName,
                fiveFileName: fiveFileName,
                sixFileName: sixFileName,
                sevenFileName: sevenFileName,
                eightFileName: eightFileName,
                nineFileName: nineFileName,
                tenFileName: tenFileName,
                result: uploadedPdfFileURL,
                results: '',
                type:"번역팩",
                transType:transType,
                manager: "매니저 배정중",
                state: "제작요청",
                timestamp: new Date(),
            };

            emailjs.send('service_aukhhik', 'template_9x9iy09', templateParams, 'i8WKsAae54nG5cmba')
            .then((result) => {
                console.log(result.text);
                setTimeout(() => setPage(currPage => currPage + 1), 0);
                window.scrollTo({ top: 0 });
            }, (error) => {
                console.log(error.text);
            });

            }, 0);
            } else {
            console.log('Document not found');
            return;
            }
            } catch (error) {
            console.error('Error', error);
            }

            };












  
            //추가 파일 모달창A
                    
            const [modalOpenA, setModalOpenA] = useState(false);

            const openModalA = () => {
            setModalOpenA(true);
            };
            const closeModalA = () => {
            setModalOpenA(false);
            };




            const ModalA = (props) => {
                // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
                const { open, close, header } = props;
              
                return (
                  // 모달이 열릴때 openModal 클래스가 생성된다.
                  <div className={open ? 'openModals modals' : 'modals'}>
                    {open ? (
                      <section>
                        <header>
              
                          <div>
                            추가 파일첨부
                          </div>
              
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                        </header>
              
              
                        <main>
              
              
                        <div className="extra-file" style={{display:'block'}}>
              
                        <span className="extra-files" style={{display:'flex'}}>
                    <label htmlFor="hidden-file-inputs-one" className="extra-custom-button">
                      <span className="extra-add">
                      파일추가
                      </span>
                    </label>
                    <div className="extra-file-name">
                    {one && <><span className="file-name">{oneFileName}</span></>} {/* 파일 이름 렌더링 */}
                    </div>
                    <span className="delete-file" onClick={clearOneFile}></span>
                      <input
                        type="file"
                        id="hidden-file-inputs-one"
                        className="file-inputs"
                        onChange={onExtraFileChange(setOne, setOneFileName)}
                      />
                      </span>
              
              
                      <span className="extra-files" style={{display:'flex'}}>
                      <label htmlFor="hidden-file-inputs-two" className="extra-custom-button">
                      <span className="extra-add">
                      파일추가
                      </span>
                    </label>
                    <div className="extra-file-name">
                    {two && <><span className="file-name">{twoFileName}</span></>} {/* 파일 이름 렌더링 */}
                    </div>
                    <span className="delete-file" onClick={clearTwoFile}></span>
                      <input
                        type="file"
                        id="hidden-file-inputs-two"
                        className="file-inputs"
                        onChange={onExtraFileChange(setTwo, setTwoFileName)}
                      />
                      </span>
                      
              
                      <span className="extra-files" style={{display:'flex'}}>
                      <label htmlFor="hidden-file-inputs-three" className="extra-custom-button">
                      <span className="extra-add">
                      파일추가
                      </span>
                    </label>
                    <div className="extra-file-name">
                    {three && <><span className="file-name">{threeFileName}</span></>} {/* 파일 이름 렌더링 */}
                    </div>
                    <span className="delete-file" onClick={clearThreeFile}></span>
                      <input
                        type="file"
                        id="hidden-file-inputs-three"
                        className="file-inputs"
                        onChange={onExtraFileChange(setThree, setThreeFileName)}
                      />
                      </span>
                      
              
                      <span className="extra-files" style={{display:'flex'}}>
                      <label htmlFor="hidden-file-inputs-four" className="extra-custom-button">
                      <span className="extra-add">
                      파일추가
                      </span>
                    </label>
                    <div className="extra-file-name">
                    {four && <><span className="file-name">{fourFileName}</span></>} {/* 파일 이름 렌더링 */}
                    </div>
                    <span className="delete-file" onClick={clearFourFile}></span>
                      <input
                        type="file"
                        id="hidden-file-inputs-four"
                        className="file-inputs"
                        onChange={onExtraFileChange(setFour, setFourFileName)}
                      />
                      </span>
                      
                      <span className="extra-files" style={{display:'flex'}}>
                      <label htmlFor="hidden-file-inputs-five" className="extra-custom-button">
                      <span className="extra-add">
                      파일추가
                      </span>
                    </label>
                    <div className="extra-file-name">
                    {five && <><span className="file-name">{fiveFileName}</span></>} {/* 파일 이름 렌더링 */}
                    </div>
                    <span className="delete-file" onClick={clearFiveFile}></span>
                      <input
                        type="file"
                        id="hidden-file-inputs-five"
                        className="file-inputs"
                        onChange={onExtraFileChange(setFive, setFiveFileName)}
                      />
                      </span>
                      
                      </div>
              
              
                        </main>
                      
                        <footer>
                          <div className='introa'>
                            <span onClick={close}>확인</span>
                        </div>
                        </footer>
                      </section>
                    ) : null}
                  </div>
                );
              };



                //추가 파일 모달창B
                    
                const [modalOpenB, setModalOpenB] = useState(false);

                const openModalB = () => {
                setModalOpenB(true);
                };
                const closeModalB = () => {
                setModalOpenB(false);
                };
    
    
    
    
                const ModalB = (props) => {
                    // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
                    const { open, close, header } = props;
                  
                    return (
                      // 모달이 열릴때 openModal 클래스가 생성된다.
                      <div className={open ? 'openModals modals' : 'modals'}>
                        {open ? (
                          <section>
                            <header>
                  
                              <div>
                                추가 파일첨부
                              </div>
                  
                              <button className="close" onClick={close}>
                                &times;
                              </button>
                            </header>
                  
                  
                            <main>
                  
                  
                            <div className="extra-file" style={{display:'block'}}>
                  
                            <span className="extra-files" style={{display:'flex'}}>
                        <label htmlFor="hidden-file-inputs-one" className="extra-custom-button">
                          <span className="extra-add">
                          파일추가
                          </span>
                        </label>
                        <div className="extra-file-name">
                        {six && <><span className="file-name">{sixFileName}</span></>} {/* 파일 이름 렌더링 */}
                        </div>
                        <span className="delete-file" onClick={clearSixFile}></span>
                          <input
                            type="file"
                            id="hidden-file-inputs-one"
                            className="file-inputs"
                            onChange={onExtraFileChange(setSix, setSixFileName)}
                          />
                          </span>
                  
                  
                          <span className="extra-files" style={{display:'flex'}}>
                          <label htmlFor="hidden-file-inputs-two" className="extra-custom-button">
                          <span className="extra-add">
                          파일추가
                          </span>
                        </label>
                        <div className="extra-file-name">
                        {seven && <><span className="file-name">{sevenFileName}</span></>} {/* 파일 이름 렌더링 */}
                        </div>
                        <span className="delete-file" onClick={clearSevenFile}></span>
                          <input
                            type="file"
                            id="hidden-file-inputs-two"
                            className="file-inputs"
                            onChange={onExtraFileChange(setSeven, setSevenFileName)}
                          />
                          </span>
                          
                  
                          <span className="extra-files" style={{display:'flex'}}>
                          <label htmlFor="hidden-file-inputs-three" className="extra-custom-button">
                          <span className="extra-add">
                          파일추가
                          </span>
                        </label>
                        <div className="extra-file-name">
                        {eight && <><span className="file-name">{eightFileName}</span></>} {/* 파일 이름 렌더링 */}
                        </div>
                        <span className="delete-file" onClick={clearEightFile}></span>
                          <input
                            type="file"
                            id="hidden-file-inputs-three"
                            className="file-inputs"
                            onChange={onExtraFileChange(setEight, setEightFileName)}
                          />
                          </span>
                          
                  
                          <span className="extra-files" style={{display:'flex'}}>
                          <label htmlFor="hidden-file-inputs-four" className="extra-custom-button">
                          <span className="extra-add">
                          파일추가
                          </span>
                        </label>
                        <div className="extra-file-name">
                        {nine && <><span className="file-name">{nineFileName}</span></>} {/* 파일 이름 렌더링 */}
                        </div>
                        <span className="delete-file" onClick={clearNineFile}></span>
                          <input
                            type="file"
                            id="hidden-file-inputs-four"
                            className="file-inputs"
                            onChange={onExtraFileChange(setNine, setNineFileName)}
                          />
                          </span>
                          
                          <span className="extra-files" style={{display:'flex'}}>
                          <label htmlFor="hidden-file-inputs-five" className="extra-custom-button">
                          <span className="extra-add">
                          파일추가
                          </span>
                        </label>
                        <div className="extra-file-name">
                        {ten && <><span className="file-name">{tenFileName}</span></>} {/* 파일 이름 렌더링 */}
                        </div>
                        <span className="delete-file" onClick={clearTenFile}></span>
                          <input
                            type="file"
                            id="hidden-file-inputs-five"
                            className="file-inputs"
                            onChange={onExtraFileChange(setTen, setTenFileName)}
                          />
                          </span>
                          
                          </div>
                  
                  
                            </main>
                          
                            <footer>
                              <div className='introa'>
                                <span onClick={close}>확인</span>
                            </div>
                            </footer>
                          </section>
                        ) : null}
                      </div>
                    );
                  };        




                  const Pages = () => {
                    if (page === 0) {
                        return(
                          <div className="transform-page-zero">

                            <div className='temform-page-a'>
                                        <div className='temform-page-emty'></div>
                                        <div className='temform-page-info'>
                                            <div className="temform-page-inner">
                                            <div className="temform-page-title">타입 선택</div>
                                            <div className="temform-page-txt">원하시는 타입을 선택해주세요.</div>
                                            </div>
                                        </div>
                            </div>


                            <div className="transform-page-c">
                                <div className="transform-pageC-inner">


                                    <div className="transform-pageC-a">
                                        <div className={transType === 'A타입' ? 'transPageC-a-imgs' : 'transPageC-a-img'}><div onClick={() => {handleClickA('A타입');}}></div></div>
                                        <div className="transPage-zero-btn">
                                            <div>
                                                <div className="transPage-zero-btnB" onClick={() => {handleClickA('A타입');}}><span>A 타입</span></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="transform-pageC-b">
                                       <div className={transType === 'B타입' ? 'transPageC-b-imgs' : 'transPageC-b-img'}><div onClick={() => {handleClickA('B타입');}}></div></div>
                                        <div className="transPage-zero-btn">
                                            <div>
                                                <div className="transPage-zero-btnB" onClick={() => {handleClickA('B타입');}}><span>B 타입</span></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            
                            <div className='temform-page-emty'></div>

                            <div className="temform-page-d">
                                
                                <div className="tPageD-zero-btns">
                                    <div className="tPageD-zero-btnsA"><span>이전</span></div>
                                    <div className="tPageD-zero-btnsB" onClick={handleNextButtonClicks}><span>다음</span></div>
                                </div>
                            
                           </div>


                          </div>
                        )
                    }else if (page === 1) {
                        return(
                          <div className="temform-page-one" style={{height:"1300px"}}>

                          <div className="agree-form-a">
                
                
                                  <div className='temform-page-a'>
                                              <div className='temform-page-emty'></div>
                                              <div className='temform-page-info'>
                                                  <div className="temform-page-inner">
                                                  <div className="temform-page-title" style={{display:"flex"}}><div><img src={temCon} style={{width:"46px", marginRight:"10px"}}/></div>번역팩 신청서</div>
                                                  </div>
                                              </div>
                                  </div>
                
                                  <div className="agree-page">
                                      <div className="agree-page-title">
                                          <div>
                                          이용 약관
                                          </div>
                                      </div>
                                  </div>
                
                                  
                                  <div className="authForm-two-b">
                                          <div className="b-inner-text">
                                          제1조(목적) 
                <br/>
                이 약관은 빅파이씨앤티가 운영하는 달리페이지(이하 “몰”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.<br/>
                <br/>
                ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」<br/>
                <br/>
                <br/><br/>
                제2조(정의)<br/>
                <br/>
                ① “몰”이란 회사가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.<br/>
                <br/>
                ② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.<br/>
                <br/>
                ③  ‘회원‘ 서비스에 접속하여 이 약관에 동의하고, 아이디와 비밀번호를 발급받은 자 또는 소셜 로그인 기능(Google계정을 이용한 회원가입 및 로그인을 의미함)으로 달리페이지 서비스의 개인정보 제공에 동의한 자<br/>
                <br/>
                ④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를 말합니다.<br/>
                <br/>
                ⑤ ‘템플릿 팩’서비스라 함은 이용자가 원하는 디자인 템플릿을 선택하고 텍스트를 입력을 하여 상품 이미지를 업로드하면 그 정보를 토대로 상세페이지를 제작하는 서비스<br/>
                <br/>
                ⑥ ‘번역팩’서비스라 함은 이용자가 제공한 외국어로 되어있는 상세페이지를 번역을 하고 편집까지 작업해서 번역 된 상세페이지를 제공하는 서비스<br/>
                <br/>
                ⑦ ‘프리미엄팩’서비스라 함은 상세페이지 기획부터 디자인,영상 GIF 포함한 상세페이지를 제공하는 서비스<br/>
                ⑧ ‘입금대기중’라 함은 이용자가 상세페이지 제작 신청을 하고 견적비용청구서를 받은 상태를 말합니다.<br/>
                <br/>
                ⑨ ‘작업대기중’라 함은 이용자가 상세페이지 견적비용에 대해 결제를 하결제 확인만 한 상태를 말합니다.<br/>
                <br/>
                <br/><br/>
                제3조 (약관 등의 명시와 설명 및 개정) <br/>
                <br/>
                ① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 달리페이지의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br/>
<br/>
                ② “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.<br/>
                <br/>
                ③ “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                <br/><br/>
                ④ “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 "몰“은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다. 
                <br/><br/>
                ⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
                <br/><br/>
                ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.
                <br/><br/><br/><br/>
                제4조(서비스의 제공 및 변경) 
                <br/><br/>
                ① “몰”은 다음과 같은 업무를 수행합니다.
                <br/><br/>
                1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결<br/>
                2. 기타 “몰”이 정하는 업무
                <br/><br/>
                ② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
                <br/><br/>
                ③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
                <br/><br/>
                ④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
                <br/><br/><br/><br/>
                제5조(서비스의 중단) <br/>
                <br/>
                ① “몰”은 컴퓨터 등 정보통신 설비의 보수 점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
                <br/><br/>
                ② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
                <br/><br/>
                ③ 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은 제8조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
                <br/><br/><br/><br/>
                제6조(상세페이지 제작 신청 및 개인정보 제공 동의 등)
                <br/><br/>
                ① “몰”이용자는 “몰”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “몰”은 이용자가 구매 신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. <br/><br/>
                    1. 재화 등의 검색 및 선택<br/>
                    2. 받는 사람의 성명, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력<br/>
                    3. 약관 내용, 청약철회권이 제한되는 서비스, 관련한 내용에 대한 확인<br/>
                    4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시<br/>
                    (예, 마우스 클릭)<br/>
                    5. 재화 등의 상세페이지 신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의<br/>
                    6. 결제방법의 선택<br/>
                    <br/><br/>
                ② “몰”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2) 개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
                <br/><br/>
                ③ “몰”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급 위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스 제공에 관한 계약 이행을 위해 필요하고 구매자의 편의 증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의 절차를 거치지 않아도 됩니다.
                <br/><br/><br/><br/>
                제7조 (계약의 성립)<br/>
                <br/>
                ① “몰”은 제6조와 같은 상세페이지 제작 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.
                <br/><br/>
                1. 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
                2. 기타 상세페이지 신청에 승낙하는 것이 “몰” 기술상 현저히 지장이 있다고 판단하는 경우<br/>
                <br/>
                ② 달리페이지 계약은 이용자가 회사에 달리페이지 이용을 신청하면 회사가 회원에게 상세페이지 제작 컨설팅 수수료 등 제반 비용을 제시하는 것을 포함하여 구체적인 계약 내용을 정하여 한 계약의 청약에 대하여 회원이 그에 응하여 그  제반 비용의 결제를 함으로써 승낙을 한 것으로 간주하며, 그 청약과 승낙으로써  달리페이지 서비스 계약이 성립한 것으로 합니다.
                <br/><br/><br/><br/>

                제8조(계약 내용의 변경ㆍ추가)<br/><br/>

                ① 수요자와 공급자는 합리적이고 객관적인 사유가 발생하여 부득이하게 계약 변경이 필요하거나 수요자의 요청에 의하여 계약 내용을 변경ㆍ추가하고자 하는 경우에는 계약의 내용과 범위를 회사와 합의 후에 변경할수 있다.<br/><br/>
                ② 상세페이지 제작 진행 중 수요자의 요청에 의하여 업무내용, 일정 등이 변경되어 공급자의 투입시간 등이 증가한 경우에는 공급자는 추가 비용을 청구한다. 이 경우 공급자는 수요자에게 산출내역서를 제출하여야 한다. <br/>
                <br/><br/><br/>
                제9조(지급방법) <br/><br/>

                ①“몰”에서 구매한 재화 또는 용역에 대한 대금 지급 방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.<br/>
                <br/>
                1. 온라인 무통장입금
                <br/><br/><br/><br/>
                제10조(상세페이지 수정)
                <br/><br/>
                ①‘이용자‘는 달리페이지의‘템플릿팩,‘번역팩’서비스 이용 시 2번의 수정 요청을 할수 있고 ‘프리미엄팩’서비스 이용 시 3번의 수정요청이 가능하다. 그 이후에 수요자의 요청에 의하여 수정은 추가 비용이 발생한다.<br/>
                <br/><br/><br/>
                제11조(청약철회 등)<br/><br/>

                ① 이용자가 달리페이지 상세페이지 제작 신청을 하고 견적 비용을 결제한후에 진행상태가 작업대기 상태일때까지는 청약철회가 가능하다.
                <br/><br/>
                ② 이용자가 회사에 달리페이지 서비스 이용을 신청한 후 회사가 제시한 상    세페이지 제작비용 등 제반 비용을 회원이 결제함으로써 달리페이지 서비    스 계약이 성립되고, 작업이 진행이 되면 청약철회를 할 수 없다.
                <br/><br/><br/><br/>
                제12조(청약철회 등의 효과)
                <br/><br/>
                ① “몰”은 환급진행이 되면 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다.
                <br/><br/><br/><br/>
                제13조(완료검사 및 인수)
                <br/><br/>
                ① 회사는 작업을 완료한 후 최종결과물에 대해 완료검사를 요청하여야 한다.<br/><br/>
                ② 수요자는 특별한 사정이 없는 한 최종결과물을 수령한 날로부터 7일 이내에 검사결과를 공급자에게 서면으로 통지하여야 하며, 이 기간 내에 통지하지 않은 경우에는 검사에 합격한 것으로 본다.<br/><br/>
                ③ 수요자는 검사 결과 최종결과물에 수정 및 보완의 필요성이 있는 경우 이를 서면으로 요구할 수 있으며, 다음과 같이 진행한다.<br/><br/>
                1. 회사의 귀책사유에 의한 경우 공급자의 비용으로 이를 지체 없이 수행하고 수요자에게 결과물을 납품하여 다시 수요자의 검사를 받는다.<br/><br/>
                2. 수요자의 귀책사유에 의한 경우 공급자는 수요자와 합의하여 수요자의 비용으로 이를 수행한다.<br/><br/><br/><br/>

                제14조(책임의 한계)<br/><br/>

                ① 회사는 달리페이지를 통하여 이루어지는 회원의 판매 및 판매 실적, 구매와 관련하여 판매 의사 또는 아이템 구매의사의 여부 및 진정성, 등록물품의 품질, 완전성, 안전성, 적법성 및 타인의 권리에 대한 비 침해성, 회원이 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 회원이 부담해야 합니다.
                <br/><br/><br/><br/>
                제15조(지식재산권 귀속 등)
                <br/><br/>
                ① 최종 결과물을 구성하는 수요자의 콘텐츠 중 수요자가 제공하는 것은 수요자의 소유이며, 공급자는 본 계약이 종료된 이후 수요자의 승인 없이 이를 사용할 수 없습니다.<br/><br/>
                ② 본 계약에 따라 수요자에게 인도된 최종결과물에 대한 지식 재산권은 계약금액 지급이 완료된 후 수요자에게 양도되며, 지식재산권의 등록에 소요되는 비용은 수요자가 부담합니다. <br/><br/>
                ③ 수요자가 선택하지 아니한 중간 결과물(공급자가 수요자에게 제출한 최종결과물을 제외한 스케치, 렌더링 등)에 대한 권리는 공급자에게 있으며, 수요자가 디자인 시안을 추가로 사용하거나 또는 중간결과물에 대한 권리를 갖고자 할 때는 비용 지급을 포함하여 별도 협의를 하여야 합니다. 
                <br/><br/><br/><br/>
                제16조(분쟁 해결)
                <br/><br/>
                1. 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해 보상처리기구를 설치․운영합니다.<br/><br/>
                2. 회사는 회원으로부터 제출되는 불만사항 및 의견을 다른 사안에 우선하여 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리 일정을 지체 없이 통지하여야 합니다. <br/><br/>
                3. 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 회원 간에 발생한 분쟁과 관련하여 회원의 피해 구제 신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁 조정 기관의 조정에 따를 수 있습니다.<br/><br/>
                <br/><br/>
                제17조(관할법원 및 준거법) <br/><br/>

                1. 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 회원 간에 발생한 소송은 제소 당시의 회사의 주소를 관할하는 지방법원의 전속관할로 합니다. <br/><br/>
                2. 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 회원 간에 발생한 소송의 준거법은 대한민국 법으로 합니다. <br/>
                                      </div>
                                      </div>
                
                
                                      <div className="authForm-two-c">
                                      <div className="c-inner-content">
                                      <div>
                                      <Agreebox checked={agree} onChange={setAgree}>
                                      <span style={{color:"red"}}>*</span>주의사항을 모두 숙지하였으며, 위와 같이 신청합니다.(필수)
                                      </Agreebox>
                                      </div>
                
                                  </div>
                                  </div>
                
                
                                  <div className="agree-page">
                                      <div className="agree-page-title">
                                          <div>
                                          개인정보 수집 및 이용목적
                                          </div>
                                      </div>
                                  </div>
                
                                  
                                  <div className="authForm-two-b">
                                          <div className="b-inner-text">
                                          (주)빅파이씨앤티(이하 ”회사‘라 함)”는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리 방침을 수립·공개합니다.<br/>
1.개인정보의 처리목적<br/>

'개인정보처리자명'은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 <br/>
제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. <br/>

1. 재화 또는 서비스 제공<br/>
상세페이지 제작 신청서, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 요금결제·정산의 목적으로 개인정보를 처리합니다. <br/>

2. 개인정보의 처리 및 보유기간<br/>

회사는 관계법령에 따라 다음과 같이 개인정보를 보유 및 이용합니다.<br/>
전자상거래 등에서의 소비자보호에 관한 법률에 따른 보유 정보 및 보유기간<br/>
① 계약 또는 청약철회 등에 관한 기록 : 5년<br/>
② 대금 결제 및 재화 등의 공급에 관한 기록 : 5년<br/>
③ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br/>
④ 표시•광고에 관한 기록 : 6개월<br/>
⑤ 통신비밀 보호법에 따른 보유 정보 및 보유기간<br/>
⑥ 웹사이트 로그 기록 자료 : 3개월<br/>
⑦ 전자금융거래법에 따른 보유 정보 및 보유기간<br/>
⑧ 전자금융거래에 관한 기록 : 5년<br/>
⑨ 위치정보의 보호 및 이용 등에 관한 법률<br/>
⑩ 개인위치정보에 관한 기록 : 6개월<br/>
3. 처리하는 개인정보 항목<br/>

회사는 다음의 개인정보 항목을 처리하고 있습니다.<br/>

① 재화 또는 서비스 제공<br/>
• 필수 항목 : 성명, 주소, 전화번호, 이메일 주소,은행 계좌 정보<br/>


4. 개인정보의 파기 절차 및 방법<br/>

① “회사”는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다. <br/> 
② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터 베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.<br/>
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br/>
1. 파기절차<br/>
'개인정보처리자명'은(는) 파기 사유가 발생한 개인정보를 선정하고, '개인정보처리자명'의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br/>
2. 파기방법<br/>
'개인정보처리자명'은(는) 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.<br/>

5 .정보 주체와 법정대리인의 권리·의무 및 행사방법<br/>

① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.<br/>
※ 만 14세 미만 아동에 관한 개인정보의 열람 등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인 정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.<br/>
② 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, '개인정보처리자명'은(는) 이에 대해 지체없이 조치하겠습니다. <br/>
③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br/>
④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.<br/>
⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/>
⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br/>

6.개인정보의 안전성 확보조치에 관한 사항<br/>

“회사”는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. <br/>
① 개인정보의 암호화
1. 이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.<br/>
② 문서보안을 위한 잠금장치 <br/>
2. 사용 개인정보가 포함된 서류, 보조 저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br/>

7.회사의 개인정보 보호 책임자 지정<br/>

“회사“는 이용자의 개인정보를 보호하고 개인 정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.<br/>
① 개인정보 보호 책임자<br/>
② 성명: 이중엽<br/>
③ 직책: 대표<br/>
④ 전화번호: 070-7776-1688<br/>
⑤ 이메일: bigpie@jungdari.com<br/>

8.개인정보의 열람 청구를 접수·처리하는 부서<br/>

정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. “회사”는 정보주체의 개인정보 열람 청구가 신속하게 처리되도록 노력하겠습니다.<br/>

개인정보 열람 청구 접수·처리 부서<br/>
① 담당자 : 이중엽 | 메일: bigpie@jungdari.com  전화번호 : 070-7776-1688 <br/>

9.정보주체의 권익 침해에 대한 구제방법<br/>

① 정보주체는 개인정보 침해로 인한 구제를 받기 위하여 <br/>
개인정보분쟁 조정위원회, 한국 인터넷진흥원 개인정보 침해신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보 침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br/>
1. 개인정보분쟁 조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br/>
2. 개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br/>
3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br/>
4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)<br/>
② “회사”는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.<br/>
‣ 개인정보보호 관련 고객 상담 및 신고<br/>
부서명 : 디자인팀<br/>
담당자 : 김두영<br/>
연락처 : 070-4034-6909<br/>
③ 「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br/>
‣ 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)<br/>

10.개인정보 처리방침의 변경<br/>
① 이 개인정보 처리방침은 2024. 1. 2일부터 적용됩니다.<br/>
② 이전 개인정보 처리 방침은 여기를 클릭해 주세요<br/>
                                      </div>
                                      </div>
                
                
                                      <div className="authForm-two-c">
                                      <div className="c-inner-content">
                                      <div>
                                      <Agreebox checked={personal} onChange={setPersonal}>
                                      <span style={{color:"red"}}>*</span>신청인의 개인정보 수집 및 이용에 동의하시겠습니까?(필수)
                                      </Agreebox>
                                      </div>
                
                                  </div>
                                  </div>
                
                
                
                              <div className="agree-page-a">
                              <div className="agree-pageA-ex">
                              </div>
                              </div>
                
                              <div className="agree-page-b">
                
                              <div className="agree-pageB-ex">
                                                          <div className="authForm-two-f">
                
                                                                  <div className="f-inner-content">
                
                                                                      <div className="f-inner-A" style={{paddingTop:"20px"}}>
                                                                      <div className="f-inner-a">
                                                                          <div className="f-a-title"><span style={{color:"red"}}>*</span>성명</div>
                                                                          <div className="f-a-input">
                                                                          <input
                                                                          type="text"
                                                                          placeholder=""
                                                                          value={name}
                                                                          onChange={(event) => setName(event.target.value)}
                                                                          />
                                                                          </div>
                                                                      </div>
                                                                      <div className="f-inner-b">
                                                                          <div className="f-b-title">사업체명</div>
                                                                          <div className="f-b-input">
                                                                          <input
                                                                          type="text"
                                                                          placeholder=""
                                                                          value={bizName}
                                                                          onChange={(event) => setBizName(event.target.value)}
                                                                          />
                                                                          </div>
                                                                      </div>
                                                                      </div>
                
                                                                      <div className="f-inner-A">
                                                                      <div className="f-inner-a">
                                                                          <div className="f-a-title"><span style={{color:"red"}}>*</span>연락처</div>
                                                                          <div className="f-a-input">
                                                                          <input
                                                                          type="text"
                                                                          placeholder=""
                                                                          value={phone}
                                                                          maxLength={13} 
                                                                          onChange={handlePhoneChange}
                                                                          />
                                                                          </div>
                                                                      </div>
                                                                      <div className="f-inner-b">
                                                                          <div className="f-b-title"><span style={{color:"red"}}>*</span>이메일</div>
                                                                          <div className="f-b-input">
                                                                          <input
                                                                          type="email"
                                                                          placeholder=""
                                                                          value={email}
                                                                          onChange={(event) => setEmail(event.target.value)}
                                                                          />
                                                                          </div>
                                                                      </div>
                                                                      </div>
                
                                                                      <div className="f-inner-A">
                                                                      <div className="f-inner-a">
                                                                          <div className="f-a-title">홈페이지 및 쇼핑몰 주소</div>
                                                                          <div className="f-a-inputL">
                                                                          <input
                                                                          type="text"
                                                                          placeholder=""
                                                                          value={webAddress}
                                                                          onChange={(event) => setWebAddress(event.target.value)}
                                                                          />
                                                                          </div>

                                                                          <div className="f-a-title">사업자 등록번호<span style={{fontSize:"16px", color:"grey"}}> *세금계산서 발행 시 필수사항</span></div>
                                                                            <div className="f-a-inputL">
                                                                            <input
                                                                            type="text"
                                                                            placeholder=""
                                                                            value={businessN}
                                                                            onChange={(event) => setBusinessN(event.target.value)}
                                                                            />
                                                                            </div>
                                                                      </div>
                                                                      </div>
                
                                                                  </div>
                                                          </div>
                              </div>
                
                              </div>
                
                
                
                
                              <div className="temform-page-d" style={{border:"none"}}>
                                
                                <div className="tPageD-zero-btns" style={{marginTop:"70px"}}>
                                  <div className="tPageD-zero-btnsA" onClick={handlePrevButtonClick}><span>이전</span></div>
                                  <div className="tPageD-zero-btnsB" onClick={handleNextButtonClick}><span>다음</span></div>
                              </div>
                          
                
                              <div className='temform-page-emty'></div>
                              <div className='temform-page-emty'></div>
                      </div>
                
                
                
                          </div>
              
                      </div>
                        )
                    }else if (page === 2) {
                        return(
                          <div className="transform-page-two">

                  {loading && <div className="loading">
                  <span></span> 
                  <span></span>
                  <span></span>  
                  </div>}

                          <div className="hide-last">



                          <div className='temform-page-a'>
                                              <div className='temform-page-emty'></div>
                                              <div className='temform-page-info'>
                                                  <div className="temform-page-inner">
                                                  <div className="temform-page-title" style={{display:"flex"}}><div><img src={temCon} style={{width:"46px", marginRight:"10px"}}/></div>번역팩 신청서</div>
                                                  </div>
                                              </div>
                                  </div>


                
  
                          <div style={{height:"100px", borderTop:"1px solid grey", display:"flex"}}>
                            <div style={{flex:2, display:"flex"}}><div style={{margin:"auto", fontSize:"20px", fontWeight:"bold"}}>상세페이지 이미지 첨부</div></div>
                            <div style={{flex:8, display:"flex"}}><div style={{flex:1.5}}><div style={{display:"flex", margin:"auto", height:"100%"}}>
                            <span className="custom-button" onClick={openModalA} style={{margin:"auto", padding:"10px 16px", fontSize:"14px", borderRadius:"8px"}}>
                            파일 첨부
                            </span>
                                    <ModalA open={modalOpenA} close={closeModalA}>
                                    </ModalA>
                              </div></div>
                              <div style={{flex:9, display:"flex"}}><div style={{margin:"auto 0px", width:"100%", maxWidth:"800px", overflow:"hidden"}}>

                              {one && <><span className="file-name">{oneFileName}</span></>} {/* 파일 이름 렌더링 */}
                              {two && <><span className="file-name">&nbsp;&nbsp;{twoFileName}</span></>} {/* 파일 이름 렌더링 */}
                              {three && <><span className="file-name">&nbsp;&nbsp;{threeFileName}</span></>} {/* 파일 이름 렌더링 */}
                              {four && <><span className="file-name">&nbsp;&nbsp;{fourFileName}</span></>} {/* 파일 이름 렌더링 */}
                              {five && <><span className="file-name">&nbsp;&nbsp;{fiveFileName}</span></>} {/* 파일 이름 렌더링 */}

                              </div></div>
                              
                              </div>
                          </div>



                          <div style={{height:"100px", borderTop:"1px solid grey", display:"flex"}}>
                            <div style={{flex:2, display:"flex"}}><div style={{margin:"auto 30px", fontSize:"20px", fontWeight:"bold"}}>상세페이지 링크</div></div>
                              <div style={{flex:8, display:"flex"}}>
                                <input placeholder="제품 원본 링크(1688, 타오바오 등)을 첨부해 주세요." style={{height:"30%", margin:"auto", width:"100%", borderRadius:"5px", border:"1px solid grey", padding:"5px"}}
                                value={prdUrl} onChange={(event) => (setPrdUrl(event.target.value))}
                                />
                              </div>
                          </div>



                          <div style={{height:"200px", borderTop:"1px solid grey"}}>
                            <div style={{fontSize:"20px", fontWeight:"bold", marginLeft:"30px", paddingTop:"20px"}}><span style={{color:"red"}}>* </span>제품 설명</div>
                            <div style={{display:"flex", marginTop:"10px"}}>
                            <textarea 
                                    style={{width:"96%", margin:"auto", height:"120px", padding:"10px", borderRadius:"8px"}} 
                                    placeholder="제품에 대한 설명 또는 고객님이 생각하시는 제품의 강점을 상세히 작성해 주세요."
                                    value={prdInfo}
                                    type="text"
                                    spellCheck="false"
                                    maxLength={1000}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                        event.preventDefault();
                                        setPrdInfo(prdInfo + '\n');
                                        }
                                        }} 
                                    onChange={(event) => (setPrdInfo(event.target.value))}/>
                            </div>
                          </div>

                          <div className='temform-page-emty'></div>


                          <div style={{height:"200px", borderTop:"1px solid grey"}}>
                            <div style={{fontSize:"20px", fontWeight:"bold", marginLeft:"30px", paddingTop:"20px"}}>요청/문의 사항</div>
                            <div style={{display:"flex", marginTop:"10px"}}>
                            <textarea 
                                    style={{width:"96%", margin:"auto", height:"120px", padding:"10px", borderRadius:"8px"}} 
                                    placeholder="기타 요청이나 문의사항이 있으시면 내용을 작성해 주세요."
                                    value={note}
                                    type="text"
                                    spellCheck="false"
                                    maxLength={1000}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                        event.preventDefault();
                                        setNote(note + '\n');
                                        }
                                        }} 
                                    onChange={(event) => (setNote(event.target.value))}/>
                            
                            </div>
                          </div>

                          <div className='temform-page-emty'></div>
                                  

                {transType === "B타입" ?
                              <>


                                  <div style={{height:"100px", borderTop:"1px solid grey", display:"flex"}}>
                                  <div style={{flex:5, display:"flex"}}><div style={{display:"block", margin:"auto"}}><div style={{margin:"auto", fontSize:"20px", fontWeight:"bold", paddingBottom:"10px", color:"rgb(68, 94, 238)"}}><span style={{color:"red"}}>*</span>상세페이지 이미지 첨부</div><div>gif 동적 이미지로 표현하고자 하는 영역을 첨부해 주세요. (그림판 등의 도구로 영역 지정)</div></div></div>
                                  <div style={{flex:5, display:"flex"}}><div style={{flex:2}}><div style={{display:"flex", margin:"auto", height:"100%"}}>
                                  <span className="custom-button" onClick={openModalB} style={{margin:"auto", padding:"10px 16px", fontSize:"14px", borderRadius:"8px"}}>
                                  파일 첨부
                                  </span>
                                          <ModalB open={modalOpenB} close={closeModalB}>
                                          </ModalB>
                                    </div></div>
                                    <div style={{flex:8, display:"flex"}}><div style={{margin:"auto 0px", width:"100%", maxWidth:"800px", overflow:"hidden"}}>

                                    {six && <><span className="file-name">{sixFileName}</span></>} {/* 파일 이름 렌더링 */}
                                    {seven && <><span className="file-name">&nbsp;&nbsp;{sevenFileName}</span></>} {/* 파일 이름 렌더링 */}
                                    {eight && <><span className="file-name">&nbsp;&nbsp;{eightFileName}</span></>} {/* 파일 이름 렌더링 */}
                                    {nine && <><span className="file-name">&nbsp;&nbsp;{nineFileName}</span></>} {/* 파일 이름 렌더링 */}
                                    {ten && <><span className="file-name">&nbsp;&nbsp;{tenFileName}</span></>} {/* 파일 이름 렌더링 */}

                                    </div></div>
                                    
                                    </div>
                                  </div>


                                  <div style={{height:"365px", borderTop:"1px solid grey"}}>
                                    <div style={{display:"flex", marginTop:"10px"}}>

                                      <div className="btype-trans-img" style={{flex:2.5, height:"326px", borderRadius:"8px"}}></div>

                                      <div style={{flex:7.5, marginLeft:"20px"}}>
                                    <textarea 
                                            style={{width:"96%", margin:"auto", height:"310px", padding:"10px", borderRadius:"8px"}} 
                                            placeholder="동적 이미지가 어떤 형태로 표현되면 좋을지 내용을 작성해 주세요&#13;&#10;1.표시된 글씨에 효과넣어주세요. 2.신발에 반짝이 효과 넣어주세요."
                                            value={gifNote}
                                            type="text"
                                            spellCheck="false"
                                            maxLength={1000}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                event.preventDefault();
                                                setGifNote(gifNote + '\n');
                                                }
                                                }} 
                                            onChange={(event) => (setGifNote(event.target.value))}/>
                                      </div>

                                    </div>
                                  </div>
                
                
                
                
                
                              <div className="temform-page-d">
                              
                              <div className="tPageD-zero-btns">
                                  <div className="tPageD-zero-btnsA" onClick={handlePrevButtonClick}><span>이전</span></div>
                                  <button className="tPageD-zero-btnsB" disabled={!six && !seven && !eight && !nine && !ten} type="submit" style={{border:"none", color:"white", fontSize:"20px", fontWeight:"bold"}}><span>신청</span></button>
                              </div>


                
                              <div className='temform-page-emty'></div>
                              <div className='temform-page-emty'></div>
                      
                              </div>


                              </>

                              :


                              <div className="temform-page-d">
                              
                              <div className="tPageD-zero-btns">
                                  <div className="tPageD-zero-btnsA" onClick={handlePrevButtonClick}><span>이전</span></div>
                                  <button className="tPageD-zero-btnsB" type="submit" style={{border:"none", color:"white", fontSize:"20px", fontWeight:"bold"}}><span>신청</span></button>
                              </div>


                
                              <div className='temform-page-emty'></div>
                              <div className='temform-page-emty'></div>
                      
                              </div>
                            
                            }



                            </div>



                                            <div className="wrap-result">

                                            </div>


                                            <div className="result">
                                            <div className="canvas">
                                            <div className="contentArea">
                                            <div className="headerArea">
                                                <div className="headerArea-left"><div className="logoimg"><img src={logo}/></div></div>
                                                <div className="headerArea-right">Tel. 070-7776-1688 / Fax. 032-714-3850 / E-mail. pi@jungdari.com<br/>2702-2703 Sambo Techno Tower 122, Jomaru-ro 385 Beon-gil, Bucheon-si, Gyeonggi-do, Korea</div>
                                            </div>
                                            <div className="mainArea">
                                                <div className="mainArea-top">
                                                <div className="mainArea-top-left">상세페이지 서비스 수수료 청구서</div>
                                                <div className="mainArea-top-right">
                                                    <div className="topArea-right-a">
                                                        <div className="rightArea-a-title">일 자</div>
                                                        <div className="rightArea-a-content">{date}</div>
                                                    </div>
                                                    <div className="topArea-right-b">
                                                        <div className="rightArea-b-title">담당자</div>
                                                        <div className="rightArea-b-content">(주)빅파이씨앤티</div>
                                                    </div>
                                                </div>
                                                </div>

                                                <div className="mainArea-middle">
                                                    <div className="mainArea-middle-one">
                                                        <div className="middleArea-one-a">
                                                        고객정보
                                                        </div>
                                                    </div>
                                                    <div className="mainArea-middle-two">
                                                        <div className="middleArea-two-a">
                                                            <div className="twoArea-a-title">성명</div>
                                                            <div className="twoArea-a-content">{name}</div>
                                                        </div>
                                                        <div className="middleArea-two-a">
                                                            <div className="twoArea-a-title">사업체명</div>
                                                            <div className="twoArea-a-content">{bizName}</div>
                                                        </div>
                                                    </div>
                                                    <div className="mainArea-middle-three">
                                                        <div className="middleArea-three-a">
                                                            <div className="threeArea-a-title">이메일</div>
                                                            <div className="threeArea-a-content">{email}</div>
                                                        </div>
                                                        <div className="middleArea-three-a">
                                                            <div className="threeArea-a-title">휴대폰</div>
                                                            <div className="threeArea-a-content">{phone}</div>
                                                        </div>
                                                    </div>
                                                    <div className="mainArea-middle-four">
                                                        <div className="middleArea-four-a">
                                                            <div className="fourArea-a-title">홈페이지 및<br/>쇼핑몰 주소</div>
                                                            <div className="fourArea-a-content" style={{overflowX:"hidden", marginLeft:"70px"}}>{webAddress}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mainArea-bottom">
                                                    <div className="mainArea-bottom-one">
                                                    아래와 같이 서비스 수수료를 청구합니다.
                                                    </div>
                                                </div>

                                                <div className="mainArea-price">

                                                <div className="mainArea-table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                        <th>NO</th>
                                                        <th>청구내용</th>
                                                        <th>금액</th>
                                                        <th>비고</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>상세페이지 번역팩</td>
                                                            <td>￦ {valueComma(fee)}</td>
                                                            <td>VAT 포함</td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                </div>
                                                </div>

                                                <div className="mainArea-total">
                                                    <div className="mainArea-total-one">
                                                    <span className="totalArea-left">총 금 액 (VAT포함)</span>
                                                    <span className="totalArea-right">￦ {valueComma(fee)}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="footerArea">
                                                <div className="footerArea-one">
                                                <div className="footerArea-one-a"><span className="spanArea-a">입금 계좌: 신한은행 140-012-325778 (주)빅파이씨앤티</span> <span className="spanArea-b">(입금 부탁드립니다)</span></div>
                                                <div className="footerArea-one-b">
                                                입금하신 금액은 전액 세금계산서 발행됩니다.</div>
                                                </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>


                          </div>
                        )
                    }else if (page === 3) {
                      return(
                        <div className="transform-page-three">

<div className='temform-page-a'>
                                        <div className='temform-page-emty'></div>
                                        <div className='temform-page-info'>
                                            <div className="temform-page-inner">
                                            <div className="temform-page-title" style={{fontSize:"12px"}}>
                                              <PortfolioTitle aniTxt={'수수료 확인 및 결재'}/>
                                            </div>
                                            <div className="temform-page-txt" style={{fontSize:"28px", color:"grey", fontWeight:"normal"}}>신청이 완료되었습니다! 아래 신청내역을 확인해 주세요.</div>
                                            </div>
                                        </div>
                            </div>

                            <div style={{height:"30px"}}></div>

                            <div className='temform-page-a' style={{height:"380px"}}>
                                        <div className='temform-page-info'>
                                            <div className="temform-page-inner" style={{width:"100%", display:"flex", height:"440px", border:"1px solid grey"}}>
                                              
                                              <div style={{margin:"auto", width:"90%", fontSize:"22px", textAlign:"left"}}>

                                              <div style={{display:"flex", padding:"15px 10px"}}>
                                                <div style={{flex:1}}>성명</div><div style={{flex:1, fontWeight:"normal", overflow:""}}>{name}</div><div style={{flex:1}}>사업체명</div><div style={{flex:1, fontWeight:"normal"}}>{bizName}</div>
                                              </div>

                                              <div style={{display:"flex", borderTop:"1px solid grey", padding:"15px 10px"}}>
                                                <div style={{flex:1}}>연락처</div><div style={{flex:1, fontWeight:"normal"}}>{phone}</div><div style={{flex:1}}>이메일</div><div style={{flex:1, fontWeight:"normal"}}>{bizName}</div>
                                              </div>

                                              <div style={{display:"flex", borderTop:"1px solid grey", padding:"15px 10px"}}>
                                                <div style={{flex:1}}>홈페이지 및 쇼핑몰 주소</div><div style={{flex:1, fontWeight:"normal"}}>{webAddress}</div><div style={{flex:1}}>서비스</div><div style={{flex:1, fontWeight:"normal"}}>번역팩</div>
                                              </div>


                                              <div style={{display:"flex", height:"100px"}}>
                                                <div style={{flex:1, display:"flex"}}>
                                                    <div style={{margin:"auto"}}>
                                                        <div>일&nbsp;&nbsp;&nbsp;자&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontWeight:"normal"}}>{date}</span></div>
                                                        <div style={{marginTop:"10px"}}>담당자&nbsp;&nbsp;&nbsp;&nbsp;<span style={{fontWeight:"normal"}}>(주)빅파이씨앤티</span></div>
                                                    </div>
                                                </div>
                                                <div style={{flex:2, display:"flex"}}>
                                                    <div style={{margin:"auto", display:"flex", width:"100%", fontSize:"28px"}}>
                                                    <div style={{flex:2, textAlign:"right", color:"rgb(68, 94, 238)"}}>총 금 액 (VAT포함)</div><div style={{flex:1, marginLeft:"20px", fontWeight:"normal"}}>₩ {valueComma(fee)}</div>
                                                    </div>
                                                </div>
                                              </div>

                                              <div style={{display:"flex", height:"140px", background:"rgb(247, 247, 247)"}}>
                                                <div style={{flex:1, display:"flex"}}>
                                                    <div style={{margin:"auto", textAlign:"center"}}>
                                                        <div>입금계좌 : 신한은행 140-012-325778&nbsp;&nbsp;(주)빅파이씨앤티<span style={{fontWeight:"normal"}}>(입금 부탁드립니다)</span></div>
                                                        <div style={{marginTop:"20px"}}><span style={{fontWeight:"normal"}}>입금하신 금액은 수입 후 전액 세금계산서 발행됩니다.</span></div>
                                                    </div>
                                                </div>
                                              </div>


                                              </div>

                                            </div>
                                        </div>
                                        
                            </div>
                            

                            <div className='temform-page-a'>
                                        <div className='temform-page-info'>
                                            <div className="temform-page-inner">

                                            <div style={{marginTop:"120px", fontSize:"30px"}}>
                                           신청내역 및 청구서는 고객님 이메일로 발송<span style={{fontWeight:"normal"}}>되었습니다.</span>
                                            </div>

                                            <div style={{textAlign:"center", paddingTop:"20px", color:"grey", fontSize:"20px", fontWeight:"normal"}}>
                                            이메일을 받지 못하셨다면, 이메일 수신함의 스팸 메일함을 확인해보세요
                                            </div>

                                            <div style={{width:"100%", display:"flex", marginTop:"30px"}}>
                                            <div style={{margin:"auto"}}>
                                            </div>
                                            </div>

                                            </div>
                                        </div>
                            </div>

                        </div>
                      )
                  }
                }



  return (
<>
<NavMenuSub />

<div className={`${page === 1 ? 'temForm-body-change' : 'transForm-body'}`}>
<form onSubmit={onSubmit}>
    {Pages()}
</form>
    </div>


    <div className="home-nine">
<div className="home-footer">
              <div className="footer-wrap">
          
                    <div className="footer-right">
                    <img src={footlogo}/>
                    </div>

                    <div className="footer-left">

                            <div className="footer-left-txt">

                            <div className="left-txt-top">
                                                <Link to='/term'>
                                                  이용약관
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
                                                  <Link to='/personal'>
                                                  개인정보취급방침
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                                  개인정보의 수집 및 이용목적
                                                </div>

                                <div className="left-txt-middle">
                                상호:(주)빅파이씨앤티 | 사업자 등록번호 : 169-81-00496 | 팩스번호 : 032-714-3850<br/>
                                  경기도 부천시 조마루로 385번길 122 삼보테크노타워 2702-3호 (주)빅파이씨앤티<br/>
                                  대표이사: 이중엽 | 통신판매 신고번호 : 제 2021-경기부천-1209호 | 개인정보보호책임자 : 김두영 | 메일: bigpie_art@jungdari.com<br/>
                                </div>

                                <div className="left-txt-bottom">
                                Copyrights2023ⓒBigpieC&amp;T. All Rights Reserved
                                </div>

                            </div>

                      </div>
                </div>
</div>
</div>

</>
  );
}

export default TranslationForm;
import React, {useRef} from 'react';
import '../css/selection.css';
import footlogo from '../asset/footer_logo.svg';
import NavMenuSub from './NavMenuSub';
import { Link } from 'react-router-dom';



function Selection({userObj}) {

    const cardRefA = useRef(null);
    const cardRefB = useRef(null);
    const cardRefC = useRef(null);



    const handleMouseEnterA = () => {
        cardRefA.current.style.transform = "scale(1.1)";
      };
    
      const handleMouseLeaveA = () => {
        cardRefA.current.style.transform = "scale(1)";
      };
    
      const handleMouseEnterB = () => {
        cardRefB.current.style.transform = "scale(1.2)";
      };
    
      const handleMouseLeaveB = () => {
        cardRefB.current.style.transform = "scale(1.1)";
      };
    
      const handleMouseEnterC = () => {
        cardRefC.current.style.transform = "scale(1.1)";
      };
    
      const handleMouseLeaveC = () => {
        cardRefC.current.style.transform = "scale(1)";
      };

      function bann() {
        alert("로그인이 필요합니다");
}



  return (
    <>
    <NavMenuSub />
    <div className='selection-body'>
        <div className='selection-list'>
            <div className='selection-list-emty'></div>
            <div className='selection-list-top'>
                <div>원하시는 서비스를 선택해주세요.</div>
            </div>
            <div className='selection-list-bottom'>
                <div className='selection-list-all'>
                { userObj ? (
                <Link to="/templateform">
                <div className='selection-list-a' ref={cardRefA} onMouseEnter={handleMouseEnterA} onMouseLeave={handleMouseLeaveA}></div>
                </Link>
                )
                :
                (
                  <a href='#'>
                  <div className='selection-list-a' onClick={bann} ref={cardRefA} onMouseEnter={handleMouseEnterA} onMouseLeave={handleMouseLeaveA}></div>
                  </a>
                )
                }
                <Link to="/translationform">
                <div className='selection-list-b' ref={cardRefB} onMouseEnter={handleMouseEnterB} onMouseLeave={handleMouseLeaveB}></div>
                </Link>

                <Link to="/premiumform">
                <div className='selection-list-c' ref={cardRefC} onMouseEnter={handleMouseEnterC} onMouseLeave={handleMouseLeaveC}></div>
                </Link>
                </div>
            </div>
        </div>
    </div>

    <div className="home-nine">
        <div className="home-footer">
                  <div className="footer-wrap">
          
                    <div className="footer-right">
                    <img src={footlogo}/>
                    </div>

                    <div className="footer-left">

                            <div className="footer-left-txt">

                            <div className="left-txt-top">
                                                <Link to='/term'>
                                                  이용약관
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
                                                  <Link to='/personal'>
                                                  개인정보취급방침
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                                  개인정보의 수집 및 이용목적
                                                </div>

                                <div className="left-txt-middle">
                                상호:(주)빅파이씨앤티 | 사업자 등록번호 : 169-81-00496 | 팩스번호 : 032-714-3850<br/>
                                  경기도 부천시 조마루로 385번길 122 삼보테크노타워 2702-3호 (주)빅파이씨앤티<br/>
                                  대표이사: 이중엽 | 통신판매 신고번호 : 제 2021-경기부천-1209호 | 개인정보보호책임자 : 김두영 | 메일: bigpie_art@jungdari.com<br/>
                                </div>

                                <div className="left-txt-bottom">
                                Copyrights2023ⓒBigpieC&amp;T. All Rights Reserved
                                </div>

                            </div>

                </div>
                </div>
          </div>
          </div>
    </>
  );
}

export default Selection;
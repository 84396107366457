import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import NavMenuSub from "./NavMenuSub";
import '../css/template.css';
import '../css/navmenu.css';
import footlogo from '../asset/footer_logo.svg';


function Template({userObj, openLoginPopup}) {
    const temTwoRef = useRef(null);
    const temFourRef = useRef(null);
    const temFiveRef = useRef(null);
    const temSevenRef = useRef(null);


    const [isBottom, setIsBottom] = useState(false);

    const handleScroll = () => {

      // 사용자가 페이지 하단에 스크롤했는지 확인
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        setIsBottom(true);
    } else {
        setIsBottom(false);
    }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    function bann() {
        alert("로그인이 필요합니다");
        openLoginPopup(); // 로그인 팝업 열기
}


    const [Page, setPage] = useState(0);

const PageRender = () => {
    if (Page === 0){
        return(
        <>
        <div className='tem-six-left'><div className='tem-left-btn' onClick={() => setPage(2)}></div></div>
        <div className='tem-six-middleA'></div>
        <div className='tem-six-right'><div className='tem-right-btn' onClick={() => setPage(1)}></div></div>
        </>
        )
    }else if (Page === 1){
        return(
            <>
            <div className='tem-six-left'><div className='tem-left-btn' onClick={() => setPage(0)}></div></div>
            <div className='tem-six-middleB'></div>
            <div className='tem-six-right'><div className='tem-right-btn' onClick={() => setPage(2)}></div></div>
            </>
        )
    }else if (Page === 2){
        return(
            <>
            <div className='tem-six-left'><div className='tem-left-btn' onClick={() => setPage(1)}></div></div>
            <div className='tem-six-middleC'></div>
            <div className='tem-six-right'><div className='tem-right-btn' onClick={() => setPage(0)}></div></div>
            </>
        )
    }
}

    //미들메뉴 선택이동
    const scrollToSection = (ref) => {
        if (ref.current) {
            const offsetTop = ref.current.offsetTop-168;
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const [isSticky, setSticky] = useState('off');
    const goRef = useRef(null); // 'header-middle' 요소에 대한 ref
    const backRef = useRef(null); // 'header-middle' 요소에 대한 ref

    useEffect(() => {
        const handleScroll = () => {
            const go = goRef.current;
            const back = backRef.current;
            
            if (go.getBoundingClientRect().top <= 0) {
                setSticky('on');
            } else if (back.getBoundingClientRect().top > 0) {
                setSticky('off');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


  return (
    <>
    <NavMenuSub />
    <div className="tem-one">
    <div className="tem-banner">

    </div>
    </div>

    <div ref={goRef} className='go'></div>

    <div className='header-middle-emty'>
    <div className={`${isSticky === 'on' ? 'fixed-middlemenu' : 'header-middle'}`}>
        <div 
        className="mheader-fixed">

            <div className='mheader-one' onClick={() => scrollToSection(temTwoRef)}></div>
            <div className='mheader-two' onClick={() => scrollToSection(temFourRef)}></div>
            <div className='mheader-three' onClick={() => scrollToSection(temFiveRef)}></div>
            <div className='mheader-four' onClick={() => scrollToSection(temSevenRef)}></div>

        
        </div>
        </div>
    </div>

    <div className={`fixed-middlebtn ${isBottom ? 'at-bottom' : ''}`}>
        <div className='fixed-btn'>
        { userObj ? (
            <Link to="/templateform">
            <div className='midbtn'><div>템플릿팩 접수하기</div></div>
            </Link>
            )
            :
            (
            <div className='midbtn' onClick={bann}><div>템플릿팩 접수하기</div></div>
            )
            }
        </div>
    </div>

    <div ref={backRef} className='back'></div>

        <div ref={temTwoRef} className="tem-two">
            <div className="tem-info">


            </div>
        </div>


        <div className="tem-three">
            <div className="tem-three-info">



            </div>
        </div>

        
        <div ref={temFourRef} className="tem-four">
            <div className="tem-four-info">



            </div>
        </div>

        <div ref={temFiveRef} className="tem-five">
            <div className="tem-five-info">



            </div>
        </div>

        <div className="tem-six">
            <div className="tem-six-info">


            {PageRender()}
            

            
            </div>
        </div>

        <div ref={temSevenRef} className="tem-seven">
            <div className="tem-seven-info">



            </div>
        </div>


        <div className="home-nine">
<div className="home-footer">
              <div className="footer-wrap">
          
                    <div className="footer-right">
                    <img src={footlogo}/>
                    </div>

                    <div className="footer-left">

                            <div className="footer-left-txt">

                            <div className="left-txt-top">
                                                <Link to='/term'>
                                                  이용약관
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
                                                  <Link to='/personal'>
                                                  개인정보취급방침
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                                  개인정보의 수집 및 이용목적
                                                </div>

                                <div className="left-txt-middle">
                                상호:(주)빅파이씨앤티 | 사업자 등록번호 : 169-81-00496 | 팩스번호 : 032-714-3850<br/>
                                  경기도 부천시 조마루로 385번길 122 삼보테크노타워 2702-3호 (주)빅파이씨앤티<br/>
                                  대표이사: 이중엽 | 통신판매 신고번호 : 제 2021-경기부천-1209호 | 개인정보보호책임자 : 김두영 | 메일: bigpie_art@jungdari.com<br/>
                                </div>

                                <div className="left-txt-bottom">
                                Copyrights2023ⓒBigpieC&amp;T. All Rights Reserved
                                </div>

                            </div>

                      </div>
                </div>
</div>
</div>


    </>
  );
}

export default Template;
import React, { useEffect } from 'react';
import anime from 'animejs';

// 인라인 CSS 스타일
const styles = {
  ml2: {
    fontWeight: 900,
    fontSize: '3.5em',
  },
  letter: {
    display: 'inline-block',
    lineHeight: '1em',
  },
};

function PortfolioTitle({aniTxt}) {
  useEffect(() => {
    // Wrap every letter in a span
    const textWrapper = document.querySelector('.ml2');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    anime.timeline({ loop: false })
      .add({
        targets: '.ml2 .letter',
        scale: [4, 1],
        opacity: [0, 1],
        translateZ: 0,
        easing: "easeOutExpo",
        duration: 950,
        delay: (el, i) => 70 * i
      }).add({
        targets: '.ml2',
        opacity: 1,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000
      });
  }, []);

  return (
    <div style={styles.ml2} className="ml2">{aniTxt}</div>
  );
}

export default PortfolioTitle;
import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';
import NavMenuHome from './NavMenuHome';
import '../css/home.css';
import bgvd from '../asset/bg_vd.mp4';
import bgtwovd from '../asset/bg_two_vd.mp4';
import secTwoOne from '../asset/sec_2_bn_01.svg';
import secTwoTwo from '../asset/sec_2_bn_02.svg';
import secTwoThree from '../asset/sec_2_bn_03.svg';
import secTwoFour from '../asset/sec_2_bn_04.svg';
import footlogo from '../asset/footer_logo.svg';
import popupImg from '../asset/summer.jpg';


function Home() {

  const [activeButtonInfo, setActiveButtonInfo] = useState('btnTem');

  const handleClickInfo = (buttonId) => {
    setActiveButtonInfo(buttonId);
};



  const cardRefA = useRef(null);
  const cardRefB = useRef(null);
  const cardRefC = useRef(null);
  const cardRefD = useRef(null);

  const handleMouseEnterA = () => {
    cardRefA.current.style.transform = "scale(1.1)";
  };

  const handleMouseLeaveA = () => {
    cardRefA.current.style.transform = "scale(1)";
  };

  const handleMouseEnterB = () => {
    cardRefB.current.style.transform = "scale(1.1)";
  };

  const handleMouseLeaveB = () => {
    cardRefB.current.style.transform = "scale(1)";
  };

  const handleMouseEnterC = () => {
    cardRefC.current.style.transform = "scale(1.1)";
  };

  const handleMouseLeaveC = () => {
    cardRefC.current.style.transform = "scale(1)";
  };

  const handleMouseEnterD = () => {
    cardRefD.current.style.transform = "scale(1.1)";
  };

  const handleMouseLeaveD = () => {
    cardRefD.current.style.transform = "scale(1)";
  };

  
  const checkTopARef = useRef(null);
  const checkTopBRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
          entry.target.classList.remove('show');
        }
      });
    }, { threshold: 0.5 });

    if (checkTopARef.current) {
      observer.observe(checkTopARef.current);
    }
    if (checkTopBRef.current) {
      observer.observe(checkTopBRef.current);
    }
  
    return () => {
      if (checkTopARef.current) {
        observer.unobserve(checkTopARef.current);
      }
      if (checkTopBRef.current) {
        observer.unobserve(checkTopBRef.current);
      }
      if (differTopRef.current) {
        observer.unobserve(differTopRef.current);
      }
      if (differMiddleRef.current) {
        observer.unobserve(differMiddleRef.current);
      }
    };
  }, []);


  const differTopRef = useRef(null);
  const differMiddleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('shows');
        } else {
          entry.target.classList.remove('shows');
        }
      });
    }, { threshold: 0.5 });

  if (differTopRef.current) {
    observer.observe(differTopRef.current);
  }
  if (differMiddleRef.current) {
    observer.observe(differMiddleRef.current);
  }

  return () => {
    if (differTopRef.current) {
      observer.unobserve(differTopRef.current);
    }
    if (differMiddleRef.current) {
      observer.unobserve(differMiddleRef.current);
    }
  };
}, []);

const stepTopRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('showss');
          } else {
            entry.target.classList.remove('showss');
          }
        });
      }, { threshold: 0.5 });

    if (stepTopRef.current) {
      observer.observe(stepTopRef.current);
    }

    return () => {
      if (stepTopRef.current) {
        observer.unobserve(stepTopRef.current);
      }
    };
    }, []);


    
  const [isSticky, setSticky] = useState('off');
  const goRef = useRef(null); // 'header-middle' 요소에 대한 ref
  const backRef = useRef(null); // 'header-middle' 요소에 대한 ref

  useEffect(() => {
      const handleScroll = () => {
          const go = goRef.current;
          const back = backRef.current;
          
          if (go.getBoundingClientRect().top <= 0) {
              setSticky('on');
          } else if (back.getBoundingClientRect().top > 0) {
              setSticky('off');
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);


  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopupChecked, setIsPopupChecked] = useState(false);

  useEffect(() => {
      const popupCloseTime = localStorage.getItem('popupCloseTime');
      if (popupCloseTime) {
          const currentTime = new Date().getTime();
          const elapsedTime = currentTime - popupCloseTime;
          const twentyFourHours = 24 * 60 * 60 * 1000;

          if (elapsedTime < twentyFourHours) {
              setIsPopupVisible(false);
          }
      }
  }, []);

  const handleCheckboxChange = () => {
      setIsPopupChecked(!isPopupChecked);
  };

  const handleClose = () => {
      if (isPopupChecked) {
          const currentTime = new Date().getTime();
          localStorage.setItem('popupCloseTime', currentTime);
      }
      setIsPopupVisible(false);
  };


  return (
    <>


            {isPopupVisible && (
                <div className="popup-containerA">
                    <div className="popupA">
                        <div className="popupA-top">
                            <Link to="/premium">
                                <img src={popupImg} alt="Popup" width={600}/>
                            </Link>

                            <div className="popupA-top-left">
                                <div>
                                    <input
                                        type="checkbox"
                                        checked={isPopupChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <div>&nbsp;&nbsp;24시간동안 보지않기</div>
                                </div>
                            </div>

                            <div className="popupA-top-right">
                                <div className="close-btnA" onClick={handleClose}>
                                    &times;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


    <div ref={goRef}></div>

    <div className='moving-menu-emty'>
    <div className={`${isSticky === 'on' ? 'fixed-moving-menu' : 'moving-menu'}`}>
    <NavMenuHome/>
    </div>
    </div>

    <div ref={backRef}></div>

<div className="home-one">
<div className="home-banner">
    <div className="home-banner-text">
    <div className="banner-inner-txt">
    <div className="inner-txt-one" style={{color:"#0068ff"}}>
      맞춤형 컨셉 <strong>기획</strong>부터 고퀄리티 <strong>디자인</strong>까지!
    </div>
    <div className="inner-txt-two" style={{color:"black"}}>
      고객이 읽는 순간,
    </div>
    <div className="inner-txt-three">
      결제로 이어지는 상세페이지!
    </div>
    </div>
    </div>
    <div className="home-banner-vd">
            <video className='video' autoPlay loop muted>
            <source src={bgvd} type='video/mp4' />
            </video>
    </div>
</div>
</div>

<div className="home-two">
<div className="home-info">
  <div className="info-top"></div>
  <div className="info-title">
    <div>서비스 소개</div>
  </div>
  <div className="info-txt">
    <div>
    달리페이지만의 커머스 노하우를 통해, 제품에 가장 적합한 상세페이지를 제작합니다.
    </div>
  </div>
  <div className="info-btn">
    <div className="info-btns">
      <button className="info-btns-one" onClick={() => handleClickInfo('btnTem')}><div>템플릿팩</div></button>
      <button className="info-btns-two" onClick={() => handleClickInfo('btnTrans')}><div>번역팩</div></button>
      <button className="info-btns-three" onClick={() => handleClickInfo('btnPre')}><div>프리미엄팩</div></button>
    </div>
  </div>

    {activeButtonInfo === "btnTem" ?

        <div className="info-img">

            <div className="info-img-top">
              <div className="info-top-title">
                <div className="Ititle">
                <div className="Ititle-a">템플릿팩</div>
                <div className="Ititle-b"></div>
                </div>
              </div>

              <div className="info-top-txt">
                <div>
                  One-Click으로 쉽고 빠른 달리페이지만의 특별한 템플릿을 사용하여<br/>
                  이미지 등록만으로 빠르게 상세페이지를 완성시켜 보세요!
                </div>
              </div>
            </div>

              <div className="info-img-bottom">

              </div>

        </div>

        :

        activeButtonInfo === "btnTrans" ?

          <div className="info-img">

          <div className="info-img-top">
            <div className="info-top-title">
              <div className="Ititle">
              <div className="Ititle-a">번역팩</div>
              <div className="Ititle-b"></div>
              </div>
            </div>

            <div className="info-top-txt">
              <div>
                 달리페이지는 번역기를 사용하지않아요.<br/>
                전문 번역 매니저를 통해 외국어 상세페이지를 자연스러운 한국어로 바꿔보세요!
              </div>
            </div>
          </div>

            <div className="info-img-bottomm">

            </div>

           </div>

        :

        <div className="info-img">

        <div className="info-img-top">
          <div className="info-top-title">
            <div className="Ititle">
            <div className="Ititle-a">프리미엄팩</div>
            <div className="Ititle-b"></div>
            </div>
          </div>

          <div className="info-top-txt">
            <div>
                재료만 준비하시면 판매자님의 의도한 컨셉에 맞게<br/>
                세부 기획부터 디자인까지 모두 다 만들어 드려요!
            </div>
          </div>
        </div>

          <div className="info-img-bottommm">

          </div>

         </div>

        }


</div>
</div>


<div className="home-twoB">
  <div className="home-twoB-info">

        <div>
          <div>같은 제품 판매여도, 상세페이지는 미세한 퀄리티에 따라<br/>매출의 차이가 하늘과 땅 차이 입니다.</div>
        </div>

        <div>

        </div>

        <div>

        </div>

  </div>
</div>

<div className="home-twoC">
  <div className="home-twoC-info">

        <div>
          <div>10만 개가 넘는 아이템을 다뤘던 우리는<br/>제품의 USP(핵심 소구 포인트)를 명확하게 분석합니다.</div>
        </div>

        <div>

        </div>

  </div>
</div>

          <div className="home-three">

          <div className="home-check">
            <div className="home-check-top">
              <div className="check-top"></div>
              <div className="check-top-a" ref={checkTopARef}><div>잘팔리는 상세페이지란?</div></div>
              <div className="check-top-b" ref={checkTopBRef}><div>구매 전환을 일으키는 강력한 헤드라인과<br/>마음을 움직이는 메시지로 구성된 상세페이지를 말합니다.</div></div>
              <div className="check-bottom"></div>
            </div>

            <div className="home-check-bottom">

              {/*
              <div className="check-bottom-a"> <div className="ABottom-wrap"> 
              <div className="bottom-a-one" ref={cardRefA} onMouseEnter={handleMouseEnterA} onMouseLeave={handleMouseLeaveA}>  <div className="image"> <img src={secTwoOne}/> </div> <div className="description">내가 판매하는 제품이 고객의<br/>어떤 문제와 관계되어 있는가?</div>  </div> 
              <div className="bottom-a-two" ref={cardRefB} onMouseEnter={handleMouseEnterB} onMouseLeave={handleMouseLeaveB}>  <div className="image"> <img src={secTwoTwo}/> </div> <div className="description">그 문제를 해결하기 위해<br/>제품은 어떤 기능을 제공하는가?</div>  </div> 
              </div> 
              </div> 
              <div className="check-bottom-b"> <div className="ABottom-wrap"> 
              <div className="bottom-b-one" ref={cardRefC} onMouseEnter={handleMouseEnterC} onMouseLeave={handleMouseLeaveC}>  <div className="image"> <img src={secTwoThree}/> </div> <div className="description">내 제품으로 문제가 해결된 상태를<br/>한눈에 보여줄 수 있는가?</div> </div> 
              <div className="bottom-b-two" ref={cardRefD} onMouseEnter={handleMouseEnterD} onMouseLeave={handleMouseLeaveD}>  <div className="image"> <img src={secTwoFour}/> </div> <div className="description">경쟁업체와 대비하여 제품의<br/>어떤 특징을 더욱 강조해야 하는가?</div>  </div> 
              </div> 
              </div>
              */}



            </div>
          </div>

          <video className='video' autoPlay loop muted>
            <source src={bgtwovd} type='video/mp4' />
          </video>

          </div>



          <div className="home-seven">
<div className="home-step">
  <div className="step-content">
    <div className="step-top">
      <div className="top-deco" ref={stepTopRef}>
      <div>제품만 준비하세요<br/>기획부터 완성까지 달리페이지가 다 해결하겠습니다.</div>
      </div>
    </div>
    <div className="step-bottom">
      <div className="step-bottom-a"></div>
      <div className="step-bottom-b"></div>
      <div className="step-bottom-c"></div>
      <div className="step-bottom-d"></div>
    </div>
    <div className="step-bottom-txt">
      <div className="bottom-a-txt"><div>기획안 구체화 상담</div></div>
      <div className="bottom-b-txt"><div>세부 프로젝트 회의</div></div>
      <div className="bottom-c-txt"><div>상세페이지 제작</div></div>
      <div className="bottom-d-txt"><div>전달 및 검토</div></div>
    </div>
  </div>
</div>
</div>

{/*
<div className="home-four">
<div className="home-differ">

    <div className="home-differ-ex">
    </div>

  <div className="differ-top" ref={differTopRef}>

    <div className="differ-top-title">
    달리페이지는 이렇게 다릅니다.
    </div>
    </div>

  <div className="differ-middle" ref={differMiddleRef}>
  <div className="differ-middle-txt">
  달리페이지는 단순히 상세페이지 제작을 대행하는 디자인 플랫폼이 아닙니다.<br/>
  글로벌 셀러로서 지난 10년간 직접 상품을 공급하고 판매한 커머스 경력을 가지고 있습니다.<br/>
  풍부한 판매 경험을 토대로 실제 제품이 잘 팔리는 핵심 디자인을 적용하여 상세페이지를 제작합니다.
  </div>
  </div>

  <div className="differ-bottom">
    <div className="differ-bottom-img">

        <div className="bottom-img-a shine"></div>
        <div className="bottom-img-b shine"></div>

    </div>
  </div>

</div>
</div>

<div className="home-five">
<div className="home-port">
</div>
</div>

*/}


<div className="home-six">
<div className="home-intro">
    <div className="intro-content">
      <div className="intro-title">포트폴리오</div>
      <div className="intro-txt">달리페이지의 상세페이지를 소개합니다.</div>
      <div className="intro-btn"><div><Link to="/portfolio"><p>자세히보기</p></Link></div></div>
    </div>
</div>
</div>


<div className="home-eight">
  <div className="home-partner">
    <div>
    Our Partner
    </div>
  </div>

        <section className='partners'>
          <div className='partners-img-container'>
            <div className='partners-img'></div>
          </div>
        </section>

</div>

<div className="home-nine">
<div className="home-footer">
              <div className="footer-wrap">
          
                    <div className="footer-right">
                    <img src={footlogo}/>
                    </div>

                    <div className="footer-left">

                            <div className="footer-left-txt">
                              
                            <div className="left-txt-top">
                                <Link to='/term'>
                                  이용약관
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
                                  <Link to='/personal'>
                                  개인정보취급방침
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                  개인정보의 수집 및 이용목적
                                </div>

                                <div className="left-txt-middle">
                                상호:(주)빅파이씨앤티 | 사업자 등록번호 : 169-81-00496 | 팩스번호 : 032-714-3850<br/>
                                  경기도 부천시 조마루로 385번길 122 삼보테크노타워 2702-3호 (주)빅파이씨앤티<br/>
                                  대표이사: 이중엽 | 통신판매 신고번호 : 제 2021-경기부천-1209호 | 개인정보보호책임자 : 김두영 | 메일: bigpie_art@jungdari.com<br/>
                                </div>

                                <div className="left-txt-bottom">
                                Copyrights2023ⓒBigpieC&amp;T. All Rights Reserved
                                </div>

                            </div>

                      </div>
                </div>
</div>
</div>

</>

  );
}

export default Home;
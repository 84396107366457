import React from 'react';
import NavMenuHome from './NavMenuHome';
import footlogo from '../asset/footer_logo.svg';
import { Link } from 'react-router-dom';


function Personal() {


  return (
  <>
  <NavMenuHome />
  <div className='selection-body'>
        <div className='selection-list' style={{height:"3100px"}}>
            <div className='selection-list-top'>
                <div>개인정보취급방침</div>
            </div>
            <div className='selection-list-bottom' style={{height:"2700px", background:"white", borderRadius:"8px", padding:"70px"}}>
                (주)빅파이씨앤티(이하 ”회사‘라 함)”는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리 방침을 수립·공개합니다.<br/>
                <br/><br/>
                1.개인정보의 처리목적<br/><br/>

                '개인정보처리자명'은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」
                제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. <br/><br/>

                ① 재화 또는 서비스 제공<br/>
                상세페이지 제작 신청서, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 요금결제·정산의 목적으로 개인정보를 처리합니다. <br/><br/><br/>

                2. 개인정보의 처리 및 보유기간<br/>

                회사는 관계법령에 따라 다음과 같이 개인정보를 보유 및 이용합니다.<br/>
                전자상거래 등에서의 소비자보호에 관한 법률에 따른 보유 정보 및 보유기간<br/><br/>
                ① 계약 또는 청약철회 등에 관한 기록 : 5년<br/><br/>
                ② 대금 결제 및 재화 등의 공급에 관한 기록 : 5년<br/><br/>
                ③ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br/><br/>
                ④ 표시•광고에 관한 기록 : 6개월<br/><br/>
                ⑤ 통신비밀 보호법에 따른 보유 정보 및 보유기간<br/><br/>
                ⑥ 웹사이트 로그 기록 자료 : 3개월<br/><br/>
                ⑦ 전자금융거래법에 따른 보유 정보 및 보유기간<br/><br/>
                ⑧ 전자금융거래에 관한 기록 : 5년<br/><br/>
                ⑨ 위치정보의 보호 및 이용 등에 관한 법률<br/><br/>
                ⑩ 개인위치정보에 관한 기록 : 6개월<br/><br/><br/>
                3. 처리하는 개인정보 항목<br/>

                회사는 다음의 개인정보 항목을 처리하고 있습니다.<br/><br/>

                ① 재화 또는 서비스 제공<br/>
                • 필수 항목 : 성명, 주소, 전화번호, 이메일 주소,은행 계좌 정보<br/><br/><br/>


                4. 개인정보의 파기 절차 및 방법<br/><br/>

                ① “회사”는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다. <br/> <br/>
                ② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터 베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.<br/><br/>
                ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br/>
                3-1. 파기절차<br/>
                '개인정보처리자명'은(는) 파기 사유가 발생한 개인정보를 선정하고, '개인정보처리자명'의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br/>
                3-2. 파기방법<br/>
                '개인정보처리자명'은(는) 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.<br/><br/><br/>

                5 .정보 주체와 법정대리인의 권리·의무 및 행사방법<br/><br/>

                ① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.<br/>
                ※ 만 14세 미만 아동에 관한 개인정보의 열람 등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인 정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.<br/><br/>
                ② 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, '개인정보처리자명'은(는) 이에 대해 지체없이 조치하겠습니다. <br/><br/>
                ③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br/><br/>
                ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.<br/><br/>
                ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/><br/>
                ⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br/><br/><br/>

                6.개인정보의 안전성 확보조치에 관한 사항<br/>

                “회사”는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. <br/><br/>
                ① 개인정보의 암호화
                1. 이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.<br/><br/>
                ② 문서보안을 위한 잠금장치 <br/>
                2-1. 사용 개인정보가 포함된 서류, 보조 저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br/><br/><br/>

                7.회사의 개인정보 보호 책임자 지정<br/>

                “회사“는 이용자의 개인정보를 보호하고 개인 정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.<br/><br/>
                ① 개인정보 보호 책임자<br/><br/>
                ② 성명: 이중엽<br/><br/>
                ③ 직책: 대표<br/><br/>
                ④ 전화번호: 070-7776-1688<br/><br/>
                ⑤ 이메일: bigpie@jungdari.com<br/><br/><br/>

                8.개인정보의 열람 청구를 접수·처리하는 부서<br/>

                정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. “회사”는 정보주체의 개인정보 열람 청구가 신속하게 처리되도록 노력하겠습니다.<br/><br/>

                개인정보 열람 청구 접수·처리 부서<br/><br/>
                ① 담당자 : 이중엽 | 메일: bigpie@jungdari.com  전화번호 : 070-7776-1688 <br/><br/><br/>

                9.정보주체의 권익 침해에 대한 구제방법<br/><br/>

                ① 정보주체는 개인정보 침해로 인한 구제를 받기 위하여 <br/>
                개인정보분쟁 조정위원회, 한국 인터넷진흥원 개인정보 침해신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보 침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br/>
                1-1. 개인정보분쟁 조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br/>
                1-2. 개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br/>
                1-3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br/>
                1-4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)<br/><br/>
                ② “회사”는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.<br/><br/>
                ‣ 개인정보보호 관련 고객 상담 및 신고<br/>
                부서명 : 디자인팀<br/>
                담당자 : 김두영<br/>
                연락처 : 070-4034-6909<br/><br/>
                ③ 「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br/>
                ‣ 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)<br/><br/><br/>

                10.개인정보 처리방침의 변경<br/><br/>
                ① 이 개인정보 처리방침은 2024. 1. 2일부터 적용됩니다.<br/><br/>
                ② 이전 개인정보 처리 방침은 여기를 클릭해 주세요<br/>
            </div>
        </div>
    </div>

    <div className="home-nine">
        <div className="home-footer">
                  <div className="footer-wrap">
          
                    <div className="footer-right">
                    <img src={footlogo}/>
                    </div>

                    <div className="footer-left">

                            <div className="footer-left-txt">

                            <div className="left-txt-top">
                                                <Link to='/term'>
                                                  이용약관
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
                                                  <Link to='/personal'>
                                                  개인정보취급방침
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                                  개인정보의 수집 및 이용목적
                                                </div>

                                <div className="left-txt-middle">
                                상호:(주)빅파이씨앤티 | 사업자 등록번호 : 169-81-00496 | 팩스번호 : 032-714-3850<br/>
                                  경기도 부천시 조마루로 385번길 122 삼보테크노타워 2702-3호 (주)빅파이씨앤티<br/>
                                  대표이사: 이중엽 | 통신판매 신고번호 : 제 2021-경기부천-1209호 | 개인정보보호책임자 : 김두영 | 메일: bigpie_art@jungdari.com<br/>
                                </div>

                                <div className="left-txt-bottom">
                                Copyrights2023ⓒBigpieC&amp;T. All Rights Reserved
                                </div>

                            </div>

                </div>
                </div>
          </div>
          </div>
  </>
  );
}

export default Personal;
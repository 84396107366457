import React, { useState } from 'react';
import '../css/popup.css'; // CSS 파일을 별도로 만들어서 import
import Login from './Login';
import SignUp from './SignUp';

function Popup({isLoginVisible, setIsLoginVisible, openLoginPopup, closeLoginPopup, loginContainerClick}) {
  {/*
  const [isLoginVisible, setIsLoginVisible] = useState(false);
*/}
  const [isSignUpVisible, setIsSignUpVisible] = useState(false);
  const [loginPage, setLoginPage] = useState(0);


{/*
  const openLoginPopup = () => {
    setLoginPage(0);
    setIsLoginVisible(true);
  };

  const closeLoginPopup = () => {
    setLoginPage(0);
    setIsLoginVisible(false);
    window.location.reload();
  };
  */}

  const openSignUpPopup = () => {
    setIsSignUpVisible(true);
  };

  const closeSignUpPopup = () => {
    setIsSignUpVisible(false);
    window.location.reload();
  };

  const loginPopupClick = (e) => {
    e.stopPropagation(); // 이벤트 버블링을 중단
  };

  const signUpContainerClick = () => {
    setIsSignUpVisible(false);
    window.location.reload();
  };

  const signUpPopupClick = (e) => {
    e.stopPropagation(); // 이벤트 버블링을 중단
  };

  return (
    <div>
      <button className='header-button' onClick={openLoginPopup}>로그인</button>

      <div id="popupContainer" className={`popup-container ${!isLoginVisible ? 'hidden' : ''}`} onClick={loginContainerClick}>
        <div className="popup" onClick={loginPopupClick}>
        <div style={{display:'flex'}}>
        <div style={{flex:9}}></div>
        <div class="close-button" onClick={closeLoginPopup} style={{flex:0.8, textAlign:'left'}}>&times;</div>
        </div>
        <div>
          <Login loginPage={loginPage} setLoginPage={setLoginPage} setIsLoginVisible={setIsLoginVisible} setIsSignUpVisible={setIsSignUpVisible} />
          </div>
        </div>
      </div>
      <span style={{border:'1px solid grey', marginLeft:'5px', marginRight:'5px'}}></span>
      <button className='header-button' onClick={openSignUpPopup}>회원가입</button>

        <div id="popupContainer" className={`popup-container ${!isSignUpVisible ? 'hidden' : ''}`} onClick={signUpContainerClick}>
        <div className="popup" onClick={signUpPopupClick}>
        <div style={{display:'flex'}}>
        <div style={{flex:9}}></div>
        <div class="close-button" onClick={closeSignUpPopup} style={{flex:0.8, textAlign:'left'}}>&times;</div>
        </div>
        <div>
            <SignUp setIsLoginVisible={setIsLoginVisible} setIsSignUpVisible={setIsSignUpVisible} />
        </div>
        </div>
        </div>
    </div>
  );
}

export default Popup;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from "../firebase.js"; // Firebase 설정에 따라 경로 변경
import { doc, getDoc } from "firebase/firestore"; 
import NavMenuHome from './NavMenuHome';
import footlogo from '../asset/footer_logo.svg';
import { Link } from 'react-router-dom';
import '../css/mypage.css'


function TemplateDetail() {

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}.${month}.${day}`;
}

  const { postId } = useParams();
  const [post, setPost] = useState(null);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(firestore, "template", postId); // "posts"는 컬렉션 이름에 따라 변경
      const docSnap = await getDoc(docRef);


      if (docSnap.exists()) {
        let postData = docSnap.data();
        // 타임스탬프를 Date 객체로 변환하고 포매팅
        if (postData.timestamp) {
          let date = postData.timestamp.toDate(); // Firestore Timestamp를 Date 객체로 변환
          postData.formattedDate = formatDate(date); // 포매팅된 날짜를 저장
        }
        setPost(postData);
      } else {
        console.log("No such document!");
      }
    };

    fetchPost();
  }, [postId]);

  function handleNextButtonClick() {
    setPage(1);
    window.scrollTo({ top: 0 });
}

function handlePrevButtonClick() {
  setPage(0);
  window.scrollTo({ top: 0 });
}

function handleNextButtonClicks() {
  setPages(currPage => currPage + 1);
  window.scrollTo({ top: 0 });
}


function handlePrevButtonClicks() {
  setPages(currPage => currPage - 1);
  window.scrollTo({ top: 0 });
}



const PageB = () => {
  if (pages === 0) {
      return(
        <>
          <div className="temform-page-b">
                                <div className="temform-pageB-title">
                                    <div>
                                    01. 인트로 영역
                                    </div>
                                </div>

                            </div>

                            <div className='temform-page-formsOne'>
                                {post.temTheme === "음식테마 A타입" ?
                                <div className="tPage-forms1-img1"></div> :
                                post.temTheme === "음식테마 B타입" ?
                                <div className="tPage-forms1-img2"></div> :
                                post.temTheme === "음식테마 C타입" ?
                                <div className="tPage-forms1-img3"></div> :
                                post.temTheme === "상품테마 A타입" ?
                                <div className="tPage-forms1-img4"></div> :
                                post.temTheme === "상품테마 B타입" ?
                                <div className="tPage-forms1-img5"></div> :
                                post.temTheme === "상품테마 C타입" ?
                                <div className="tPage-forms1-img6"></div> :
                                post.temTheme === "굿즈테마 A타입" ?
                                <div className="tPage-forms1-img7"></div> :
                                post.temTheme === "굿즈테마 B타입" ?
                                <div className="tPage-forms1-img8"></div> :
                                post.temTheme === "패션테마 A타입" ?
                                <div className="tPage-forms1-img9"></div> :
                                <div className="tPage-forms1-img10"></div>
                                }

                                <div className="tPage-forms-txt">

                                    
                                    <div className="tPage-forms-inner">


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>1</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>로고파일을 첨부해주세요.</div></div>


                                    <div style={{width:"190px", display:"flex"}}><div style={{flex:8, display:'flex', overflow:"hidden"}}>{post.one && <><span className="file-name" style={{margin:"auto 5px"}}>{post.oneFileName}</span></>} {/* 파일 이름 렌더링 */}</div><div style={{flex:2, display:"flex"}}></div></div>


                                    </div>

                                    <div className="tPage-warning" style={{marginTop:"10px", color:"grey"}}>
                                        * 최소 사이즈 300px 이상 / *png파일 또는 Ai파일(jpg의 경우 퀄리티가 떨어질 수 있습니다)
                                    </div>


                                    <div style={{height:"70px"}}></div>
                                    

                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>2</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>메인 타이틀 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.two}/>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>3</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>서브 타이틀 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.three}/>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>4</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>이미지 파일을 첨부해주세요.</div></div>
                                   

                                    <div style={{width:"190px", display:"flex"}}><div style={{flex:8, display:'flex', overflow:"hidden"}}>{post.four && <><span className="file-name" style={{margin:"auto 5px"}}>{post.fourFileName}</span></>} {/* 파일 이름 렌더링 */}</div><div style={{flex:2, display:"flex"}}></div></div>


                                    </div>

                                    <div className="tPage-warning" style={{marginTop:"10px", color:"grey"}}>
                                        * 최소 사이즈 800px 이상
                                    </div>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>5</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>타이틀 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.five}/>
                                

                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>6</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>이미지 파일을 첨부해주세요.</div></div>
                                

                                    <div style={{width:"190px", display:"flex"}}><div style={{flex:8, display:'flex', overflow:"hidden"}}>{post.six && <><span className="file-name" style={{margin:"auto 5px"}}>{post.sixFileName}</span></>} {/* 파일 이름 렌더링 */}</div><div style={{flex:2, display:"flex"}}></div></div>


                                    </div>

                                    <div className="tPage-warning" style={{marginTop:"10px", color:"grey"}}>
                                        * 최소 사이즈 800px 이상
                                    </div>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>7</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.seven}/>


                                    <div style={{height:"70px"}}></div>


                                    
                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>8</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.eight}/>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>9</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>이미지 파일을 첨부해주세요.</div></div>
                                

                                    <div style={{width:"190px", display:"flex"}}><div style={{flex:8, display:'flex', overflow:"hidden"}}>{post.nine && <><span className="file-name" style={{margin:"auto 5px"}}>{post.nineFileName}</span></>} {/* 파일 이름 렌더링 */}</div><div style={{flex:2, display:"flex"}}></div></div>


                                    </div>

                                    <div className="tPage-warning" style={{marginTop:"10px", color:"grey"}}>
                                        * 최소 사이즈 800px 이상
                                    </div>


                                    
                                    </div>

                                
                                </div>
                            </div>

                            <div className="temform-page-d">
                                
                                <div className="tPageD-zero-btns">
                                    <div className="tPageD-zero-btnsB" onClick={handleNextButtonClicks}><span>다음</span></div>
                                </div>
                            </div>
        </>
      )
  }else if(pages === 1){
    return(
      <>
        <div className="temform-page-b">
                                <div className="temform-pageB-title">
                                    <div>
                                    02. 제품 특장점 영역
                                    </div>
                                </div>

                            </div>

                            <div className='temform-page-formsTwo'>
                            {post.temTheme === "음식테마 A타입" ?
                                <div className="tPage-forms2-img1"></div> :
                                post.temTheme === "음식테마 B타입" ?
                                <div className="tPage-forms2-img2"></div> :
                                post.temTheme === "음식테마 C타입" ?
                                <div className="tPage-forms2-img3"></div> :
                                post.temTheme === "상품테마 A타입" ?
                                <div className="tPage-forms2-img4"></div> :
                                post.temTheme === "상품테마 B타입" ?
                                <div className="tPage-forms2-img5"></div> :
                                post.temTheme === "상품테마 C타입" ?
                                <div className="tPage-forms2-img6"></div> :
                                post.temTheme === "굿즈테마 A타입" ?
                                <div className="tPage-forms2-img7"></div> :
                                post.temTheme === "굿즈테마 B타입" ?
                                <div className="tPage-forms2-img8"></div> :
                                post.temTheme === "패션테마 A타입" ?
                                <div className="tPage-forms2-img9"></div> :
                                <div className="tPage-forms2-img10"></div>
                                }

                                <div className="tPage-forms-txt">

                                    
                                    <div className="tPage-forms-inner">


                                    

                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>10</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>대 타이틀 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.ten}/>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>11</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>타이틀 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.eleven}/>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>12</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>이미지 파일을 첨부해주세요.</div></div>


                                    <div style={{width:"190px", display:"flex"}}><div style={{flex:8, display:'flex', overflow:"hidden"}}>{post.twelve && <><span className="file-name" style={{margin:"auto 5px"}}>{post.twelveFileName}</span></>} {/* 파일 이름 렌더링 */}</div><div style={{flex:2, display:"flex"}}></div></div>


                                    </div>

                                    <div className="tPage-warning" style={{marginTop:"10px", color:"grey"}}>
                                        * 최소 사이즈 800px 이상
                                    </div>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>13</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>타이틀 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.thirteen}/>
                                

                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>14</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>이미지 파일을 첨부해주세요.</div></div>
                                    

                                    <div style={{width:"190px", display:"flex"}}><div style={{flex:8, display:'flex', overflow:"hidden"}}>{post.fourteen && <><span className="file-name" style={{margin:"auto 5px"}}>{post.fourteenFileName}</span></>} {/* 파일 이름 렌더링 */}</div><div style={{flex:2, display:"flex"}}></div></div>


                                    </div>

                                    <div className="tPage-warning" style={{marginTop:"10px", color:"grey"}}>
                                        * 최소 사이즈 800px 이상
                                    </div>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>15</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>타이틀 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.fifteen}/>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>16</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>이미지 파일을 첨부해주세요.</div></div>
                                   

                                    <div style={{width:"190px", display:"flex"}}><div style={{flex:8, display:'flex', overflow:"hidden"}}>{post.sixteen && <><span className="file-name" style={{margin:"auto 5px"}}>{post.sixteenFileName}</span></>} {/* 파일 이름 렌더링 */}</div><div style={{flex:2, display:"flex"}}></div></div>


                                    </div>

                                    <div className="tPage-warning" style={{marginTop:"10px", color:"grey"}}>
                                        * 최소 사이즈 800px 이상
                                    </div>

                                    
                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>17</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>이미지 파일을 첨부해주세요.</div></div>


                                    <div style={{width:"190px", display:"flex"}}><div style={{flex:8, display:'flex', overflow:"hidden"}}>{post.seventeen && <><span className="file-name" style={{margin:"auto 5px"}}>{post.seventeenFileName}</span></>} {/* 파일 이름 렌더링 */}</div><div style={{flex:2, display:"flex"}}></div></div>


                                    </div>

                                    <div className="tPage-warning" style={{marginTop:"10px", color:"grey"}}>
                                        * 최소 사이즈 800px 이상
                                    </div>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>18</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.eighteen}/>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>장점 부분 추가 텍스트 요청사항</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    value={post.strong}/>

                                    
                                    </div>

                                
                                </div>
                            </div>

                            <div className="temform-page-d">
                                
                                <div className="tPageD-zero-btns">
                                    <div className="tPageD-zero-btnsA" onClick={handlePrevButtonClicks}><span>이전</span></div>
                                    <div className="tPageD-zero-btnsB" onClick={handleNextButtonClicks}><span>다음</span></div>
                                </div>
                            
                            </div>
      </>
    )
  }else if(pages === 2){
    return(
      <>
               <div className="temform-page-b">
                                <div className="temform-pageB-title">
                                    <div>
                                    03. 상품정보고지 영역
                                    </div>
                                </div>

                            </div>

                            <div className='temform-page-formsThree'>
                                {post.temTheme === "음식테마 A타입" ?
                                <div className="tPage-forms3-img1"></div> :
                                post.temTheme === "음식테마 B타입" ?
                                <div className="tPage-forms3-img2"></div> :
                                post.temTheme === "음식테마 C타입" ?
                                <div className="tPage-forms3-img3"></div> :
                                post.temTheme === "상품테마 A타입" ?
                                <div className="tPage-forms3-img4"></div> :
                                post.temTheme === "상품테마 B타입" ?
                                <div className="tPage-forms3-img5"></div> :
                                post.temTheme === "상품테마 C타입" ?
                                <div className="tPage-forms3-img6"></div> :
                                post.temTheme === "굿즈테마 A타입" ?
                                <div className="tPage-forms3-img7"></div> :
                                post.temTheme === "굿즈테마 B타입" ?
                                <div className="tPage-forms3-img8"></div> :
                                post.temTheme === "패션테마 A타입" ?
                                <div className="tPage-forms3-img9"></div> :
                                <div className="tPage-forms3-img10"></div>
                                }

                                <div className="tPage-forms-txt">

                                    
                                    <div className="tPage-forms-inner">


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>19</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>이미지 파일을 첨부해주세요.</div></div>


                                    <div style={{width:"190px", display:"flex"}}><div style={{flex:8, display:'flex', overflow:"hidden"}}>{post.nineteen && <><span className="file-name" style={{margin:"auto 5px"}}>{post.nineteenFileName}</span></>} {/* 파일 이름 렌더링 */}</div><div style={{flex:2, display:"flex"}}></div></div>


                                    </div>

                                    <div className="tPage-warning" style={{marginTop:"10px", color:"grey"}}>
                                        * 최소 사이즈 500px 이상
                                    </div>


                                    <div style={{height:"70px"}}></div>
                                    

                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>20</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>제품정보 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    placeholder="텍스트를 입력해주세요"
                                    value={post.twenty}/>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>21</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>배송정보 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    placeholder="텍스트를 입력해주세요"
                                    value={post.twentyOne}/>


                                    <div style={{height:"70px"}}></div>



                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>22</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>교환 및 환불정보 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    placeholder="텍스트를 입력해주세요"
                                    value={post.twentyTwo}/>


                                    <div style={{height:"70px"}}></div>


                                    <div className="tPage-title-a" style={{display:"flex"}}><div style={{display:"flex"}}>
                                    <div style={{marginRight:"10px", fontSize:"20px", display:"flex"}}><div style={{width:"26px", height:"26px", borderRadius:"100px", background:"#a476f0", color:"white", display:"flex", margin:"auto"}}><div style={{margin:"auto"}}>23</div></div></div>
                                    <div style={{fontWeight:"bold", fontSize:"22px", margin:"auto 0px", marginRight:"20px"}}>상품정보고지 텍스트</div></div>
                                    </div>

                                    <div style={{height:"10px"}}></div>
                                    <textarea 
                                    style={{width:"96%", borderRadius:"8px", height:"100px", border:"1px solid grey", padding:"10px"}} 
                                    placeholder="텍스트를 입력해주세요"
                                    value={post.twentyThree}/>




                                    </div>

                                
                                </div>
                            </div>

                            <div className="temform-page-d">
                                
                                <div className="tPageD-zero-btns">
                                    <div className="tPageD-zero-btnsA" onClick={handlePrevButtonClicks}><span>이전</span></div>
                                </div>
                            </div>
      </>
    )
  }
}


  
const PageA = () => {
  if (page === 0) {
      return(
        <div className='selection-body' style={{height:"1400px"}}>
        <div className='selection-list'>

            <div className='selection-list-top'style={{height:"100px", display:"flex", paddingTop:"50px"}}>
                <div style={{width:"100%", height:"50px", margin:"auto", display:"flex", paddingBottom:"10px" , borderBottom:"1px solid #ccc"}}>
                  <div style={{flex:1.2, display:"flex", borderRight:"1px solid #ccc"}}>
                    <div style={{fontSize:"26px", margin:"auto", cursor:"pointer", color:"#0068ff"}} onClick={handlePrevButtonClick}>상세정보</div>
                  </div>
                  <div style={{flex:1.2, display:"flex"}}>
                    <div style={{fontSize:"26px", margin:"auto", cursor:"pointer"}} onClick={handleNextButtonClick}>작업내역</div>
                  </div>
                  <div style={{flex:7.6}}>
                  </div>
                  
                </div>
            </div>

            {post ? (
        <div>
          {/* 포스트 내용을 화면에 표시 */}
          <div style={{ height:"80px", display:"flex"}}>
            <div style={{flex:0.9, display:"flex"}}><div className='alert' style={{margin:"auto", width:"100%", height:"100%"}}></div></div>
            <div style={{flex:7.5, display:"flex"}}>
            {post.state === '제작요청' ?
              <div style={{margin:"auto", marginLeft:"30px", color:"grey", fontSize:"22px"}}>"신청이 접수되었습니다. 입금을 진행해주세요!"</div>
              :
              post.state === '작업 대기중' ?
              <div style={{margin:"auto", marginLeft:"30px", color:"grey", fontSize:"22px"}}>"입금이 확인되었습니다. 수정이 필요한 내용이 없는지 확인해주세요!"</div>
              :
              post.state === '작업 진행중' ?
              <div style={{margin:"auto", marginLeft:"30px", color:"grey", fontSize:"22px"}}>"작업을 열심히 진행하고 잇습니다. 잠시만 기다려주세요!"</div>
              :
              post.state === '작업완료' ?
              <div style={{margin:"auto", marginLeft:"30px", color:"grey", fontSize:"22px"}}>"작업이 완료되었습니다. 피드백을 부탁드려요!"</div>
              :
              post.state === '최종완료' ?
              <div style={{margin:"auto", marginLeft:"30px", color:"grey", fontSize:"22px"}}>"최종완료 되었습니다. 이용해주셔서 감사합니다!"</div>
              :
              <></>
            }
            </div>
            <div style={{flex:1.4, display:"flex"}}>
              {/*
              <div style={{margin:"auto", width:"130px", height:"36px", display:"flex", borderRadius:"20px", fontSize:"20px", fontWeight:"bold",  background:"#464646", color:"white", cursor:"pointer"}}><div style={{margin:"auto"}}>결과물 확인</div></div>
              */}
            </div>
            <div style={{flex:1.4, display:"flex"}}>
              <a href={`${post.result}`} target='_blank'>
              <div style={{margin:"auto", width:"130px", height:"36px", display:"flex", borderRadius:"20px", fontSize:"20px", fontWeight:"bold", background:"#464646", color:"white", cursor:"pointer"}}><div style={{margin:"auto"}}>견적서 확인</div></div>
              </a>
            </div>
          </div>

          <div style={{ height:"120px", display:"flex"}}>
            <div style={{flex:1.2, display:"flex"}}><div style={{margin:"auto", marginLeft:"14px", fontSize:"30px", fontWeight:"bold"}}>{post.type}</div></div>
            <div style={{flex:6, display:"flex"}}><div style={{margin:"auto", marginLeft:"2px", fontSize:"18px", color:"grey"}}>No : {post.docId}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;일자 : {post.formattedDate} ~ {post.state !== '최종완료' ? '진행중' : '완료'}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;테마 : {post.temTheme}</div></div>
            <div style={{flex:2, display:"flex"}}><div style={{margin:"auto"}}></div></div>
            <div style={{flex:1, display:"flex"}}>
              {/*
              <div style={{margin:"auto", width:"100px", height:"36px", display:"flex", borderRadius:"20px", fontSize:"20px", fontWeight:"bold", background:"#b5b5b5", color:"white", cursor:"pointer"}}><div style={{margin:"auto"}}>삭제</div></div>
              */}
            </div>
          </div>

          <div style={{ height:"500px", display:"flex"}}>
            <div style={{flex:2.6, display:"flex", flexDirection:"column", fontSize:"26px", fontWeight:"bold", background:"#0068ff", color:"white"}}>
              <div style={{margin:"auto", flex:2.5, width:"100%", display:"flex"}}><div style={{margin:"auto"}}>담당매니저</div></div>
              <div style={{margin:"auto", flex:5, width:"100%", display:"flex"}}>
                <div className={`${post.manager === '매니저 배정중' ? 'manager' : post.manager === '윤재민' ? 'manager-a' : post.manager === '김두영' ? 'manager-b' : post.manager === '권정근' ? 'manager-c' : post.manager === '황지음' ? 'manager-d' : post.manager === '곽혜진' ? 'manager-e' : post.manager === '백미라' ? 'manager-f' : 'manager'}`} style={{margin:"auto", width:"100%", height:"100%", borderRadius:"150px"}}></div>
              </div>
              <div style={{margin:"auto", flex:3.5, width:"100%", display:"flex"}}><div style={{margin:"auto"}}> 
              {post.manager === '매니저 배정중' ?
              <div style={{textAlign:"center"}}><div style={{fontSize:"22px", fontWeight:"normal", marginBottom:"8px"}}></div><div>미배정</div></div> 
              :
              post.manager === '윤재민' ?
              <div style={{textAlign:"center"}}><div style={{fontSize:"22px", fontWeight:"normal", marginBottom:"8px"}}>세심하고 꼼꼼한</div><div>윤재민 매니저</div></div> 
              :
              post.manager === '김두영' ?
              <div style={{textAlign:"center"}}><div style={{fontSize:"22px", fontWeight:"normal", marginBottom:"8px"}}>정교하고 전문적인</div><div>김두영 매니저</div></div> 
              :
              post.manager === '권정근' ?
              <div style={{textAlign:"center"}}><div style={{fontSize:"22px", fontWeight:"normal", marginBottom:"8px"}}>유연하고 창의적인</div><div>권정근 매니저</div></div> 
              :
              post.manager === '황지음' ?
              <div style={{textAlign:"center"}}><div style={{fontSize:"22px", fontWeight:"normal", marginBottom:"8px"}}>친절하고 감성적인</div><div>황지음 매니저</div></div> 
              :
              post.manager === '곽혜진' ?
              <div style={{textAlign:"center"}}><div style={{fontSize:"22px", fontWeight:"normal", marginBottom:"8px"}}>예리하고 분석적인</div><div>곽혜진 매니저</div></div> 
              :
              post.manager === '곽혜진' ?
              <div style={{textAlign:"center"}}><div style={{fontSize:"22px", fontWeight:"normal", marginBottom:"8px"}}>재능있고 열정적인</div><div>백미라 매니저</div></div> 
              :
              <div style={{textAlign:"center"}}><div style={{fontSize:"22px", fontWeight:"normal"}}></div><div>미배정</div></div> 
              }
              
              </div></div>
            </div>
            <div style={{flex:2.6, display:"flex", flexDirection:"column", fontSize:"24px", fontWeight:"bold", background:"#e2eeff"}}>
              <div style={{margin:"auto", flex:1, width:"100%", display:"flex", borderTop:"1px solid #ccc"}}><div style={{margin:"auto"}}>성 명</div></div>
              <div style={{margin:"auto", flex:1, width:"100%", display:"flex", borderTop:"1px solid #ccc"}}><div style={{margin:"auto"}}>사업체명</div></div>
              <div style={{margin:"auto", flex:1, width:"100%", display:"flex", borderTop:"1px solid #ccc"}}><div style={{margin:"auto"}}>연락처</div></div>
              <div style={{margin:"auto", flex:1, width:"100%", display:"flex", borderTop:"1px solid #ccc"}}><div style={{margin:"auto"}}>이메일</div></div>
              <div style={{margin:"auto", flex:1, width:"100%", display:"flex", borderTop:"1px solid #ccc", borderBottom:"1px solid #ccc"}}><div style={{margin:"auto"}}>홈페이지 및 쇼핑몰</div></div>
            </div>
            <div style={{flex:4.8, display:"flex", flexDirection:"column", fontSize:"24px"}}>
              <div style={{margin:"auto", flex:1, width:"100%", display:"flex", borderTop:"1px solid #ccc", borderRight:"1px solid #ccc"}}><div style={{margin:"auto"}}>{post.name}</div></div>
              <div style={{margin:"auto", flex:1, width:"100%", display:"flex", borderTop:"1px solid #ccc", borderRight:"1px solid #ccc"}}><div style={{margin:"auto"}}>{post.bizName}</div></div>
              <div style={{margin:"auto", flex:1, width:"100%", display:"flex", borderTop:"1px solid #ccc", borderRight:"1px solid #ccc"}}><div style={{margin:"auto"}}>{post.phone}</div></div>
              <div style={{margin:"auto", flex:1, width:"100%", display:"flex", borderTop:"1px solid #ccc", borderRight:"1px solid #ccc"}}><div style={{margin:"auto"}}>{post.email}</div></div>
              <div style={{margin:"auto", flex:1, width:"100%", display:"flex", borderTop:"1px solid #ccc", borderBottom:"1px solid #ccc", borderRight:"1px solid #ccc"}}><div style={{margin:"auto"}}>{post.webAddress}</div></div>
            </div>
          </div>

          <div style={{ height:"120px", display:"flex"}}>
            <div style={{flex:1.2, display:"flex", paddingTop:"40px"}}><div style={{margin:"auto", marginLeft:"14px", fontSize:"30px", fontWeight:"bold"}}>진행상태</div></div>
            <div style={{flex:3, display:"flex"}}><div style={{margin:"auto", marginLeft:"2px", fontSize:"16px", color:"grey"}}></div></div>
            <div style={{flex:4, display:"flex"}}><div style={{margin:"auto"}}></div></div>
            <div style={{flex:1, display:"flex"}}><div style={{margin:"auto"}}></div></div>
          </div>

          <div style={{ height:"120px", display:"flex", borderBottom:"1px solid #ccc", fontSize:"20px", fontWeight:"bold", color:"white"}}>
            <div style={{flex:1, display:"flex"}}>
              {post.state === '제작요청' ?
              <div style={{margin:"auto", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#0068ff"}}><div style={{margin:"auto"}}>제작요청</div></div>
              :
              <div style={{margin:"auto", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#b5b5b5"}}><div style={{margin:"auto"}}>제작요청</div></div>
              }
              </div>
            <div style={{flex:1, display:"flex"}}>
              {post.state === '작업 대기중' ?
              <div style={{margin:"auto", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#0068ff"}}><div style={{margin:"auto"}}>작업 대기중</div></div>
              :
              <div style={{margin:"auto", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#b5b5b5"}}><div style={{margin:"auto"}}>작업 대기중</div></div>
              }
              </div>
            <div style={{flex:1, display:"flex"}}>
            {post.state === '작업 진행중' ?
              <div style={{margin:"auto", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#0068ff"}}><div style={{margin:"auto"}}>작업 진행중</div></div>
              :
              <div style={{margin:"auto", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#b5b5b5"}}><div style={{margin:"auto"}}>작업 진행중</div></div>
               }
              </div>
            <div style={{flex:1, display:"flex"}}>
            {post.state === '작업완료' ?
              <div style={{margin:"auto", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#0068ff"}}><div style={{margin:"auto"}}>작업완료 / 피드백</div></div>
              :
              <div style={{margin:"auto", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#b5b5b5"}}><div style={{margin:"auto"}}>작업완료 / 피드백</div></div>
              }
              </div>
            <div style={{flex:1, display:"flex"}}>
            {post.state === '최종완료' ?
              <div style={{margin:"auto", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#0068ff"}}><div style={{margin:"auto"}}>최종완료</div></div>
              :
              <div style={{margin:"auto", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#b5b5b5"}}><div style={{margin:"auto"}}>최종완료</div></div>
              }
              </div>
          </div>

          <div style={{ height:"140px", display:"flex", fontSize:"20px", fontWeight:"bold", borderTop:"1px solid #ccc"}}>
            <div style={{flex:1, display:"flex"}}><div style={{margin:"auto"}}></div></div>
            {/*
            <div style={{flex:1, display:"flex"}}><div style={{margin:"auto", border:"1px solid black", width:"180px", height:"36px", display:"flex", borderRadius:"20px", background:"#464646", color:"white", cursor:"pointer"}}><div style={{margin:"auto"}}>환불요청</div></div></div>
            */}
            <div style={{flex:1, display:"flex"}}><div style={{margin:"auto"}}></div></div>
          </div>



        </div>
      ) : (
        <p>Loading...</p>
      )}
        </div>
    </div>
      )
      }else if (page === 1) {
        return(
          <div className='selection-body' style={{height:"2400px"}}>
          <div className='selection-list'>
  
              <div className='selection-list-top'style={{height:"100px", display:"flex", paddingTop:"50px"}}>
                  <div style={{width:"100%", height:"50px", margin:"auto", display:"flex", paddingBottom:"10px" , borderBottom:"1px solid #ccc"}}>
                    <div style={{flex:1.2, display:"flex", borderRight:"1px solid #ccc"}}>
                      <div style={{fontSize:"26px", margin:"auto", cursor:"pointer"}} onClick={handlePrevButtonClick}>상세정보</div>
                    </div>
                    <div style={{flex:1.2, display:"flex"}}>
                      <div style={{fontSize:"26px", margin:"auto", cursor:"pointer", color:"#0068ff"}} onClick={handleNextButtonClick}>작업내역</div>
                    </div>
                    <div style={{flex:7.6}}>
                    </div>
                    
                  </div>
              </div>
  
              {post ? (
           <div>
           <div style={{ paddingTop:"40px", paddingBottom:"20px", fontSize:"34px", fontWeight:"bold", paddingLeft:"28px"}}>{post.type}</div>
           {PageB()}
           </div>
              )
              :
              (
                <></>
              )}

        </div>
        </div>
        )
      }
    };


  return (
  <>
  <NavMenuHome />
 {PageA()}

    <div className="home-nine">
        <div className="home-footer">
                  <div className="footer-wrap">
          
                    <div className="footer-right">
                    <img src={footlogo}/>
                    </div>

                    <div className="footer-left">

                            <div className="footer-left-txt">

                            <div className="left-txt-top">
                                                <Link to='/term'>
                                                  이용약관
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
                                                  <Link to='/personal'>
                                                  개인정보취급방침
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                                  개인정보의 수집 및 이용목적
                                                </div>

                                <div className="left-txt-middle">
                                상호:(주)빅파이씨앤티 | 사업자 등록번호 : 169-81-00496 | 팩스번호 : 032-714-3850<br/>
                                  경기도 부천시 조마루로 385번길 122 삼보테크노타워 2702-3호 (주)빅파이씨앤티<br/>
                                  대표이사: 이중엽 | 통신판매 신고번호 : 제 2021-경기부천-1209호 | 개인정보보호책임자 : 김두영 | 메일: bigpie_art@jungdari.com<br/>
                                </div>

                                <div className="left-txt-bottom">
                                Copyrights2023ⓒBigpieC&amp;T. All Rights Reserved
                                </div>

                            </div>

                </div>
                </div>
          </div>
          </div>
  </>
  );
}

export default TemplateDetail;
import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, where, query, orderBy } from 'firebase/firestore';
import { firestore } from "../firebase.js";
import Pagination from './components/Pagination.js';
import mypage from '../asset/mp_bn.jpg';
import NavMenuHome from './NavMenuHome';
import footlogo from '../asset/footer_logo.svg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const ITEMS_PER_PAGE = 10;


function MyPage({ userObj }) {

  const [currentPage, setCurrentPage] = useState(1);
  const [posts, setPosts] = useState([]);

  const [selectedPost, setSelectedPost] = useState(null);

  const [activeButtonInfo, setActiveButtonInfo] = useState('temTem');


useEffect(() => {

  const userId = userObj.uid; 
  
  const unsubscribe = onSnapshot(
    query(
      collection(firestore, 'template'), 
      where('creatorId', '==', userId),
      orderBy('timestamp', 'desc')
    ),
    (querySnapshot) => {
      const tempPosts = [];
      querySnapshot.forEach((doc) => {
      const data = doc.data();

      if (data.hasOwnProperty('agree') && data.hasOwnProperty('name')) {

        tempPosts.push({ 
          id: doc.id, 
          agree: data.agree, 
          personal: data.personal,
                            name: data.name,
                            bizName: data.bizName,
                            phone: data.phone,
                            email: data.email,
                            webAddress: data.webAddress,
                            temTheme: data.temTheme,

                            one: data.one,
                            two: data.two,
                            three: data.three,
                            four: data.four,
                            five: data.five,
                            six: data.six,
                            seven: data.seven,
                            eight: data.eight,
                            nine: data.nine,
                            ten: data.ten,
                            eleven: data.eleven,
                            twelve: data.twelve,
                            thirteen: data.thirteen,
                            fourteen: data.fourteen,
                            fifteen: data.fifteen,
                            sixteen: data.sixteen,
                            seventeen: data.seventeen,
                            eighteen: data.eighteen,
                            strong: data.strong,
                            nineteen: data.nineteen,
                            twenty: data.twenty,
                            twentyOne: data.twentyOne,
                            twentyTwo: data.twentyTwo,
                            twentyThree: data.twentyThree,

                            oneFileName: data.oneFileName,
                            fourFileName: data.fourFileName,
                            sixFileName: data.sixFileName,
                            nineFileName: data.nineFileName,
                            twelveFileName: data.twelveFileName,
                            fourteenFileName: data.fourteenFileName,
                            sixteenFileName: data.sixteenFileName,
                            seventeenFileName: data.seventeenFileName,
                            nineteenFileName: data.nineteenFileName,

                            result: data.result,
                            type: data.type,
                            manager: data.manager,
                            state: data.state,
                            timestamp: data.timestamp,
           
                          });
                        }
                      });
                      setPosts(tempPosts);
                    });
                

                  return () => unsubscribe();
                }, [userObj.uid]); 





                const navigate = useNavigate();

                const handlePostClick = (post) => {
                  navigate(`/templatedetail/${post.id}`);
                };


const getPostStyle = (post) => {
  return post.id === selectedPost?.id ? { } : {};
};



const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};



const getTotalPages = () => {
  return Math.ceil(posts.length / ITEMS_PER_PAGE);
};

const paginatedPosts = () => {
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  return posts.slice(startIndex, startIndex + ITEMS_PER_PAGE).map((post, index) => {
    return (

      <div className="post" style={getPostStyle(post)}>
      <div style={{display:"flex", width:"100%", background:"white", height:"80px", fontSize:"20px", marginTop:"14px", borderRadius:"8px", border:"1px solid #ccc"}}>
                                                     <div style={{flex:0.6, display:"flex", borderRight:"1px solid #ccc", height:"46px", margin:"auto"}}><span style={{margin:"auto"}}>{posts.length - (index + (currentPage - 1) * ITEMS_PER_PAGE)}</span></div>
                                                      <div style={{flex:1, display:"flex", borderRight:"1px solid #ccc", height:"46px", margin:"auto"}}><span style={{margin:"auto"}}>{post.timestamp && post.timestamp.toDate().toLocaleDateString()}</span></div> 
                                                      <div style={{flex:1, display:"flex", borderRight:"1px solid #ccc", height:"46px", margin:"auto"}}><span style={{margin:"auto"}}>{post.type}</span></div>
                                                      <div style={{flex:1, display:"flex", borderRight:"1px solid #ccc", height:"46px", margin:"auto"}}><span style={{margin:"auto", color:"#0068ff"}}>{post.state}</span></div>
                                                      <div style={{flex:1, display:"flex"}}><span style={{margin:"auto"}}>{post.manager}</span></div>
                                                      <div style={{flex:1, display:"flex"}}><span style={{margin:"auto"}}><span onClick={() => handlePostClick(post)} style={{padding:"10px 20px", borderRadius:"5px", background:"#0068ff", color:"white", fontSize:"22px", fontWeight:"bold", cursor:"pointer"}}>상세보기</span></span></div>
                                                      </div>
      </div>

    );
  });
};






  return (
    <>
    <NavMenuHome/>
    <div className='transform-page-two'>
                                  <div className='temform-page-a'>

                                              <div className='temform-page-emty'></div>
                                              <div className='temform-page-info'>
                                                  <div className="temform-page-inner">
                                                  <div className="temform-page-title" style={{display:"flex"}}><div>마이페이지<br/><div style={{fontSize:"20px", color:"grey"}}>{userObj.email}</div></div></div>
                                                  </div>
                                              </div>
                                  </div>

                                  <div className='temform-page-a'>
                                    <img src={mypage} />
                                  </div>

                                  <div className='temform-page-a'>
                                  <div style={{height:"70px", borderTop:"1px solid grey", borderBottom:"1px solid grey", display:"flex", marginTop:"20px"}}>
                                  <div style={{flex:2, display:"flex"}}><div style={{margin:"auto", fontSize:"20px"}}>[작업 진행중]으로 변경되면 수정, 취소 및 환불이 불가능합니다.</div></div>
                                </div>

                                <div className='info-btn' style={{width:"100%", display:"flex", height:"70px", marginTop:"50px", marginBottom:"20px"}}>
                                <div className="info-btns" style={{height:"68px", display:"flex"}}>
                                </div>
                                </div>



                                { activeButtonInfo === "temTem" ? 

<>
                                <div style={{height:"980px", display:"flex", borderRadius:"8px"}}>
                                  <div style={{margin:"0px auto", width:"1100px"}}>


                                  {paginatedPosts()}
                                                
                                  </div>
                                </div>
                                
                                <Pagination
                                    totalPages={getTotalPages()}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                  />
</>
                                :
                                <></>
                                              }




                              </div>
                              </div>

                          





                        <div className="home-nine">
                    <div className="home-footer">
                                  <div className="footer-wrap">
                              
                                        <div className="footer-right">
                                        <img src={footlogo}/>
                                        </div>

                                        <div className="footer-left">

                                                <div className="footer-left-txt">

                                                <div className="left-txt-top">
                                                <Link to='/term'>
                                                  이용약관
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
                                                  <Link to='/personal'>
                                                  개인정보취급방침
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                                  개인정보의 수집 및 이용목적
                                                </div>

                                                    <div className="left-txt-middle">
                                                    상호:(주)빅파이씨앤티 | 사업자 등록번호 : 169-81-00496 | 팩스번호 : 032-714-3850<br/>
                                                      경기도 부천시 조마루로 385번길 122 삼보테크노타워 2702-3호 (주)빅파이씨앤티<br/>
                                                      대표이사: 이중엽 | 통신판매 신고번호 : 제 2021-경기부천-1209호 | 개인정보보호책임자 : 김두영 | 메일: bigpie_art@jungdari.com<br/>
                                                    </div>

                                                    <div className="left-txt-bottom">
                                                    Copyrights2023ⓒBigpieC&amp;T. All Rights Reserved
                                                    </div>

                                                </div>

                                          </div>
                                    </div>
                    </div>
                    </div>
    </>
  );
}

export default MyPage;
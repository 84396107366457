import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import {auth} from '../firebase';
import Popup from './Popup';
import '../css/navigation.css';
import logo from '../asset/main_logo.svg';

function Navigation({userObj, refreshUser, isLoginVisible, setIsLoginVisible, openLoginPopup, closeLoginPopup, loginContainerClick}) {

  const navigate = useNavigate();

  const handleLogout=()=>{
    signOut(auth).then(()=>{
      console.log('successfully logged out');
      refreshUser(); // 사용자 상태 업데이트
      navigate('/');
    }).catch((err)=>{
      console.log(err);
    })
  }

  return (
<div className="header">

<div className="header-family">

<a href="/">
<div className="header-family-logo"></div>
</a>

<a href="/">
<div className="header-family-listA"></div>
</a>

<a href="https://jungdari.com/"
  target="_blank"
  rel="noopener noreferrer">
<div className="header-family-listB"></div>
</a>

<a href="https://www.darlilogis.com/"
  target="_blank"
  rel="noopener noreferrer">
<div className="header-family-listC"></div>
</a>

<a href="https://darlidata.com/"
  target="_blank"
  rel="noopener noreferrer">
<div className="header-family-listD"></div>
</a>

</div>

<div className="header-top">
<div className="header-logo">
  <Link to="/">
  <img src={logo}/>
  </Link>
</div>

<div className="header-profile">
{userObj ? (
    <>


    <button className='header-button'><Link to="/mypage">마이페이지</Link></button>

    <span style={{border:'1px solid grey', marginLeft:'5px', marginRight:'5px'}}></span>

    <button className='btn btn-secondary btn-md'
    style={{border:"none", backgroundColor:"white", cursor:"pointer", fontSize:"16px", fontWeight:"600"}}
      onClick={handleLogout}>
      LOGOUT
    </button>

</>
):(
    <>
    <div>
    <Popup isLoginVisible={isLoginVisible} setIsLoginVisible={setIsLoginVisible} openLoginPopup={openLoginPopup} closeLoginPopup={closeLoginPopup} loginContainerClick={loginContainerClick}/>
    </div>
</>
)
}
</div>


</div>



</div>
  );
}

export default Navigation;
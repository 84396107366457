import React from 'react';
import '../css/navmenu.css';
import tem from '../asset/nav_01.svg';
import trans from '../asset/nav_02.svg';
import pre from '../asset/nav_03.svg';
import hot from '../asset/HOT.svg'
import { Link } from 'react-router-dom';

function NavMenuSub() {


  return (
  <div 
  className="header-fixed">
  <div className="header-menu">

  <Link to="/template">
  <div className='header-menu-one'>
    <img src={tem}/>
    <div>템플릿팩</div>
    <div style={{width:"20px", height:"100%"}}>
    <img src={hot} style={{width:"20px"}}/>
    </div>
  </div>
  </Link>

  <Link to="/translation">
  <div className='header-menu-two'>
  <img src={trans}/>
    <div>번역팩</div>
    <div></div>
  </div>
  </Link>

  <Link to="/premium">
  <div className='header-menu-three'>
  <img src={pre}/>
    <div>프리미엄팩</div>
    <div></div>
  </div>
  </Link>

  <div style={{border:"1px solid grey", height:"20px", margin:"auto 0"}}></div>

  <div className='header-menu-four'>
  <div></div>
    <div><Link to="/portfolio">포트폴리오</Link></div>
    <div></div>
  </div>
  </div>

  <div className="header-register">

  </div>
  </div>
  );
}

export default NavMenuSub;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence, GoogleAuthProvider, signInWithPopup, fetchSignInMethodsForEmail, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import google from '../asset/googleicon.svg'

function Login({ loginPage, setLoginPage, setIsLoginVisible, setIsSignUpVisible }) {
  const [email, setEmail] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  

  
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      // 로그인유지
      await setPersistence(auth, browserLocalPersistence);
      await signInWithPopup(auth, provider);
      navigate("/");
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        const methods = await fetchSignInMethodsForEmail(auth, error.email);
        if (methods[0] === "password") {
          const userPassword = prompt("이 계정의 비밀번호를 입력하세요:");
          const credential = GoogleAuthProvider.credential(null, error.email);
          
          signInWithEmailAndPassword(auth, error.email, userPassword)
            .then((result) => {
              return result.user.linkWithCredential(credential);
            })
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              setError("계정 연결 실패");
            });
        }
      } else {
        setError("Google 로그인 실패");
        //console.log(error)
      }
    }
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // 로그인유지
      await setPersistence(auth, browserLocalPersistence);

      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      if (!userCredential.user.emailVerified) {
        setError('이메일을 인증해주세요.');
        setLoading(false);
        return;
      }

      navigate('/'); // 이메일이 인증된 경우, 홈페이지로 리디렉션
    } catch (error) {
      console.error(error);
      setError('로그인 실패: 잘못된 이메일 또는 비밀번호입니다.');
    } finally {
      if (error.code !== 'auth/email-not-verified') {
        setLoading(false);
      }
    }
  };

  const handlePasswordReset = async () => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      alert('비밀번호 재설정 링크가 이메일로 전송되었습니다.\n이메일이 등록되어 있지 않은 경우, 링크가 전송되지 않을 수 있습니다.');
    } catch (error) {
      // 오류 처리
      alert('오류 발생: ' + error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
{/*
  const LoginPages = () => {
    if (loginPage === 0) {
        return(
          <>
          <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="이메일"
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
        />
        <input
          type="password"
          placeholder="비밀번호"
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
        <button type="submit" disabled={loading}>
          {loading ? '로그인 중...' : '로그인'}
        </button>
      </form>
      {error && <p>{error}</p>}
      <button onClick={handleGoogleSignIn}>Google로 시작하기</button>
      <div>
      <span onClick={()=>{setLoginPage((currPage) => currPage + 1)}}>reset password</span>
      </div>
          </>
        )
    }else if (loginPage === 1) {
      return(
        <>
        <input
        type="email"
        value={resetEmail}
        onChange={(e) => setResetEmail(e.target.value)}
        placeholder="이메일 주소 입력"
      />
      <button onClick={handlePasswordReset}>비밀번호 재설정 이메일 보내기</button>
      {message && <p>{message}</p>}
      <div>
      <span onClick={()=>{setLoginPage((currPage) => currPage - 1)}}>취소</span>
      </div>
        </>
      )
    }
  };
*/}

  return (
    <>
     {loginPage === 0 && (
    <div class="flex flex-col items-center">
      <div style={{width:'340px'}}>
    <button class="google-button" onClick={handleGoogleSignIn} style={{width:'92%', margin:'0 auto', justifyContent:'center', fontWeight:600}}>
    <img src={google} alt="Google Logo" class="google-logo"/>
    Continue with Google</button>
    </div>

    <div class="divider">or</div>
    <form onSubmit={handleLogin} style={{width:'340px'}}>
    <div style={{textAlign:'center'}}>
  <input
    type="email"
    placeholder="Email" class="email-input"
    onChange={(e) => setEmail(e.target.value)}
    disabled={loading}
  />
  </div>
  <div style={{textAlign:'center'}}>
  <input
    type="password"
    placeholder="Password" class="password-input"
    onChange={(e) => setPassword(e.target.value)}
    disabled={loading}
  />
  </div>
  <div>
  <button class="login-button" type="submit" disabled={loading} style={{ width:'92%', margin:'0 auto', justifyContent:'center', fontWeight:600, padding:'10px 0', marginTop:'20px'}}>
    {loading ? 'Logging..' : 'Log In'}
  </button>
  <div style={{textAlign:'center'}}>{error && <p>{error}</p>}</div>
  </div>
</form>
<div class="additional-options" style={{fontWeight:600}}>
<span class="reset-password-button" style={{fontSize:'15px', fontWeight:600}} onClick={()=>{setLoginPage(1)}}>Reset password</span>
<span class="account-option" style={{fontSize:'15px', fontWeight:600}}>
    계정이 없으신가요? <a href="#" class="create-account-link" onClick={()=>{{setIsSignUpVisible(true); setIsLoginVisible(false)}}}>회원가입</a>
</span>
</div>
  </div>
     )}

{loginPage === 1 && (
    <div class="flex flex-col items-center">
       <div style={{width:'340px', textAlign:'center'}}>
       <div style={{fontWeight:600, fontSize:'24px', marginBottom:'30px'}}><span>패스워드 초기화 이메일을</span><br/><span>입력해주세요</span></div>
       <div style={{textAlign:'center'}}>
  <input
  type="email"
  value={resetEmail}
  onChange={(e) => setResetEmail(e.target.value)}
  placeholder="Email"
  class="email-input"
/>
</div>
<button class="login-button" disabled={loading} style={{ width:'92%', margin:'0 auto', justifyContent:'center', fontWeight:600, padding:'10px 0', marginTop:'20px'}} onClick={handlePasswordReset}>Reset password</button>
<div class="additional-options" style={{fontWeight:600}}>
<span class="account-option"  style={{fontSize:'15px'}}> <a href="#" class="create-account-link" onClick={()=>{setLoginPage(0)}}>Cancel</a></span>
</div>

</div>
</div>
)}
  </>
  );
}

export default Login;
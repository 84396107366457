import '../../css/Pagination.css';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const MAX_VISIBLE_PAGES = 5;
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  const getVisiblePages = () => {
    if (totalPages <= MAX_VISIBLE_PAGES) {
      return pages;
    }

    const firstVisiblePage = Math.max(1, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
    const lastVisiblePage = Math.min(totalPages, firstVisiblePage + MAX_VISIBLE_PAGES - 1);

    if (lastVisiblePage - firstVisiblePage < MAX_VISIBLE_PAGES - 1) {
      return pages.slice(-MAX_VISIBLE_PAGES);
    }

    return pages.slice(firstVisiblePage - 1, lastVisiblePage);
  };

  return (
    <nav>
      <ul className="pagination">
        {currentPage !== 1 && (
          <li className="page-item">
            <button className="page-link" onClick={() => onPageChange(currentPage - 1)}>
              Prev
            </button>
          </li>
        )}
        {getVisiblePages().map((page) => (
          <li
            key={page}
            className={`page-item ${currentPage === page ? 'active' : ''}`}
          >
            <button
              className="page-link"
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          </li>
        ))}
        {currentPage !== totalPages && (
          <li className="page-item">
            <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>
              Next
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
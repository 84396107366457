import React from 'react';
import NavMenuHome from './NavMenuHome';
import footlogo from '../asset/footer_logo.svg';
import { Link } from 'react-router-dom';


function Term() {


  return (
  <>
  <NavMenuHome />
  <div className='selection-body'>
        <div className='selection-list' style={{height:"4600px"}}>
            <div className='selection-list-top'>
                <div>이용약관</div>
            </div>
            <div className='selection-list-bottom' style={{height:"4100px", background:"white", borderRadius:"8px", padding:"60px"}}>
                제1조(목적) 
                <br/>
                이 약관은 빅파이씨앤티가 운영하는 달리페이지(이하 “몰”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.<br/>
                <br/>
                ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」<br/>
                <br/>
                <br/><br/>
                제2조(정의)<br/>
                <br/>
                ① “몰”이란 회사가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.<br/>
                <br/>
                ② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.<br/>
                <br/>
                ③  ‘회원‘ 서비스에 접속하여 이 약관에 동의하고, 아이디와 비밀번호를 발급받은 자 또는 소셜 로그인 기능(Google계정을 이용한 회원가입 및 로그인을 의미함)으로 달리페이지 서비스의 개인정보 제공에 동의한 자<br/>
                <br/>
                ④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를 말합니다.<br/>
                <br/>
                ⑤ ‘템플릿 팩’서비스라 함은 이용자가 원하는 디자인 템플릿을 선택하고 텍스트를 입력을 하여 상품 이미지를 업로드하면 그 정보를 토대로 상세페이지를 제작하는 서비스<br/>
                <br/>
                ⑥ ‘번역팩’서비스라 함은 이용자가 제공한 외국어로 되어있는 상세페이지를 번역을 하고 편집까지 작업해서 번역 된 상세페이지를 제공하는 서비스<br/>
                <br/>
                ⑦ ‘프리미엄팩’서비스라 함은 상세페이지 기획부터 디자인,영상 GIF 포함한 상세페이지를 제공하는 서비스<br/>
                ⑧ ‘입금대기중’라 함은 이용자가 상세페이지 제작 신청을 하고 견적비용청구서를 받은 상태를 말합니다.<br/>
                <br/>
                ⑨ ‘작업대기중’라 함은 이용자가 상세페이지 견적비용에 대해 결제를 하결제 확인만 한 상태를 말합니다.<br/>
                <br/>
                <br/><br/>
                제3조 (약관 등의 명시와 설명 및 개정) <br/>
                <br/>
                ① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 달리페이지의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br/>
<br/>
                ② “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.<br/>
                <br/>
                ③ “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                <br/><br/>
                ④ “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 "몰“은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다. 
                <br/><br/>
                ⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
                <br/><br/>
                ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.
                <br/><br/><br/><br/>
                제4조(서비스의 제공 및 변경) 
                <br/><br/>
                ① “몰”은 다음과 같은 업무를 수행합니다.
                <br/><br/>
                1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결<br/>
                2. 기타 “몰”이 정하는 업무
                <br/><br/>
                ② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
                <br/><br/>
                ③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
                <br/><br/>
                ④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
                <br/><br/><br/><br/>
                제5조(서비스의 중단) <br/>
                <br/>
                ① “몰”은 컴퓨터 등 정보통신 설비의 보수 점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
                <br/><br/>
                ② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
                <br/><br/>
                ③ 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은 제8조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
                <br/><br/><br/><br/>
                제6조(상세페이지 제작 신청 및 개인정보 제공 동의 등)
                <br/><br/>
                ① “몰”이용자는 “몰”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “몰”은 이용자가 구매 신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. <br/><br/>
                    1. 재화 등의 검색 및 선택<br/>
                    2. 받는 사람의 성명, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력<br/>
                    3. 약관 내용, 청약철회권이 제한되는 서비스, 관련한 내용에 대한 확인<br/>
                    4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시<br/>
                    (예, 마우스 클릭)<br/>
                    5. 재화 등의 상세페이지 신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의<br/>
                    6. 결제방법의 선택<br/>
                    <br/><br/>
                ② “몰”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2) 개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
                <br/><br/>
                ③ “몰”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급 위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스 제공에 관한 계약 이행을 위해 필요하고 구매자의 편의 증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의 절차를 거치지 않아도 됩니다.
                <br/><br/><br/><br/>
                제7조 (계약의 성립)<br/>
                <br/>
                ① “몰”은 제6조와 같은 상세페이지 제작 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.
                <br/><br/>
                1. 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
                2. 기타 상세페이지 신청에 승낙하는 것이 “몰” 기술상 현저히 지장이 있다고 판단하는 경우<br/>
                <br/>
                ② 달리페이지 계약은 이용자가 회사에 달리페이지 이용을 신청하면 회사가 회원에게 상세페이지 제작 컨설팅 수수료 등 제반 비용을 제시하는 것을 포함하여 구체적인 계약 내용을 정하여 한 계약의 청약에 대하여 회원이 그에 응하여 그  제반 비용의 결제를 함으로써 승낙을 한 것으로 간주하며, 그 청약과 승낙으로써  달리페이지 서비스 계약이 성립한 것으로 합니다.
                <br/><br/><br/><br/>

                제8조(계약 내용의 변경ㆍ추가)<br/><br/>

                ① 수요자와 공급자는 합리적이고 객관적인 사유가 발생하여 부득이하게 계약 변경이 필요하거나 수요자의 요청에 의하여 계약 내용을 변경ㆍ추가하고자 하는 경우에는 계약의 내용과 범위를 회사와 합의 후에 변경할수 있다.<br/><br/>
                ② 상세페이지 제작 진행 중 수요자의 요청에 의하여 업무내용, 일정 등이 변경되어 공급자의 투입시간 등이 증가한 경우에는 공급자는 추가 비용을 청구한다. 이 경우 공급자는 수요자에게 산출내역서를 제출하여야 한다. <br/>
                <br/><br/><br/>
                제9조(지급방법) <br/><br/>

                ①“몰”에서 구매한 재화 또는 용역에 대한 대금 지급 방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.<br/>
                <br/>
                1. 온라인 무통장입금
                <br/><br/><br/><br/>
                제10조(상세페이지 수정)
                <br/><br/>
                ①‘이용자‘는 달리페이지의‘템플릿팩,‘번역팩’서비스 이용 시 2번의 수정 요청을 할수 있고 ‘프리미엄팩’서비스 이용 시 3번의 수정요청이 가능하다. 그 이후에 수요자의 요청에 의하여 수정은 추가 비용이 발생한다.<br/>
                <br/><br/><br/>
                제11조(청약철회 등)<br/><br/>

                ① 이용자가 달리페이지 상세페이지 제작 신청을 하고 견적 비용을 결제한후에 진행상태가 작업대기 상태일때까지는 청약철회가 가능하다.
                <br/><br/>
                ② 이용자가 회사에 달리페이지 서비스 이용을 신청한 후 회사가 제시한 상    세페이지 제작비용 등 제반 비용을 회원이 결제함으로써 달리페이지 서비    스 계약이 성립되고, 작업이 진행이 되면 청약철회를 할 수 없다.
                <br/><br/><br/><br/>
                제12조(청약철회 등의 효과)
                <br/><br/>
                ① “몰”은 환급진행이 되면 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다.
                <br/><br/><br/><br/>
                제13조(완료검사 및 인수)
                <br/><br/>
                ① 회사는 작업을 완료한 후 최종결과물에 대해 완료검사를 요청하여야 한다.<br/><br/>
                ② 수요자는 특별한 사정이 없는 한 최종결과물을 수령한 날로부터 7일 이내에 검사결과를 공급자에게 서면으로 통지하여야 하며, 이 기간 내에 통지하지 않은 경우에는 검사에 합격한 것으로 본다.<br/><br/>
                ③ 수요자는 검사 결과 최종결과물에 수정 및 보완의 필요성이 있는 경우 이를 서면으로 요구할 수 있으며, 다음과 같이 진행한다.<br/><br/>
                1. 회사의 귀책사유에 의한 경우 공급자의 비용으로 이를 지체 없이 수행하고 수요자에게 결과물을 납품하여 다시 수요자의 검사를 받는다.<br/><br/>
                2. 수요자의 귀책사유에 의한 경우 공급자는 수요자와 합의하여 수요자의 비용으로 이를 수행한다.<br/><br/><br/><br/>

                제14조(책임의 한계)<br/><br/>

                ① 회사는 달리페이지를 통하여 이루어지는 회원의 판매 및 판매 실적, 구매와 관련하여 판매 의사 또는 아이템 구매의사의 여부 및 진정성, 등록물품의 품질, 완전성, 안전성, 적법성 및 타인의 권리에 대한 비 침해성, 회원이 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 회원이 부담해야 합니다.
                <br/><br/><br/><br/>
                제15조(지식재산권 귀속 등)
                <br/><br/>
                ① 최종 결과물을 구성하는 수요자의 콘텐츠 중 수요자가 제공하는 것은 수요자의 소유이며, 공급자는 본 계약이 종료된 이후 수요자의 승인 없이 이를 사용할 수 없습니다.<br/><br/>
                ② 본 계약에 따라 수요자에게 인도된 최종결과물에 대한 지식 재산권은 계약금액 지급이 완료된 후 수요자에게 양도되며, 지식재산권의 등록에 소요되는 비용은 수요자가 부담합니다. <br/><br/>
                ③ 수요자가 선택하지 아니한 중간 결과물(공급자가 수요자에게 제출한 최종결과물을 제외한 스케치, 렌더링 등)에 대한 권리는 공급자에게 있으며, 수요자가 디자인 시안을 추가로 사용하거나 또는 중간결과물에 대한 권리를 갖고자 할 때는 비용 지급을 포함하여 별도 협의를 하여야 합니다. 
                <br/><br/><br/><br/>
                제16조(분쟁 해결)
                <br/><br/>
                1. 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해 보상처리기구를 설치․운영합니다.<br/><br/>
                2. 회사는 회원으로부터 제출되는 불만사항 및 의견을 다른 사안에 우선하여 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리 일정을 지체 없이 통지하여야 합니다. <br/><br/>
                3. 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 회원 간에 발생한 분쟁과 관련하여 회원의 피해 구제 신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁 조정 기관의 조정에 따를 수 있습니다.<br/><br/>
                <br/><br/>
                제17조(관할법원 및 준거법) <br/><br/>

                1. 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 회원 간에 발생한 소송은 제소 당시의 회사의 주소를 관할하는 지방법원의 전속관할로 합니다. <br/><br/>
                2. 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 회원 간에 발생한 소송의 준거법은 대한민국 법으로 합니다. <br/>
            </div>
        </div>
    </div>

    <div className="home-nine">
        <div className="home-footer">
                  <div className="footer-wrap">
          
                    <div className="footer-right">
                    <img src={footlogo}/>
                    </div>

                    <div className="footer-left">

                            <div className="footer-left-txt">

                            <div className="left-txt-top">
                                                <Link to='/term'>
                                                  이용약관
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
                                                  <Link to='/personal'>
                                                  개인정보취급방침
                                                  </Link>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                                  개인정보의 수집 및 이용목적
                                                </div>

                                <div className="left-txt-middle">
                                상호:(주)빅파이씨앤티 | 사업자 등록번호 : 169-81-00496 | 팩스번호 : 032-714-3850<br/>
                                  경기도 부천시 조마루로 385번길 122 삼보테크노타워 2702-3호 (주)빅파이씨앤티<br/>
                                  대표이사: 이중엽 | 통신판매 신고번호 : 제 2021-경기부천-1209호 | 개인정보보호책임자 : 김두영 | 메일: bigpie_art@jungdari.com<br/>
                                </div>

                                <div className="left-txt-bottom">
                                Copyrights2023ⓒBigpieC&amp;T. All Rights Reserved
                                </div>

                            </div>

                </div>
                </div>
          </div>
          </div>
  </>
  );
}

export default Term;
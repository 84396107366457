import { useState, useEffect, useRef } from "react";
import { Route, Routes } from 'react-router-dom';
import { auth } from './firebase'
import Home from './component/Home';
import Login from './component/Login'
import SignUp from './component/SignUp'
import MyPage from "./component/MyPage";

import './App.css';
import Navigation from "./component/Navigation";
import Template from "./component/Template";
import Translation from "./component/Translation";
import Premium from "./component/Premium";
import Portfolio from "./component/Portfolio";
import Selection from "./component/Selection";
import TranslationForm from "./component/TranslationForm";
import PremiumForm from "./component/PremiumForm";
import TemplateForm from "./component/TemplateForm";
import ScrollToTop from "./component/components/ScrollToTop";
import Term from "./component/Term";
import Personal from "./component/Personal";
import TemplateDetail from "./component/TemplateDetail";

{/*
const ChannelTalk = () => {
  useEffect(() => {
    ChannelService.boot({
      "pluginKey": "60e44584-1535-49d6-a079-d83bb7788c15",
    });
  }, []);

  return ""
}
*/}

function App() {

  const [init, setInit] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userObj, setUserObj] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if(user && user.emailVerified){
        setIsLoggedIn(true);
        setUserObj(user);
      } else {
        setIsLoggedIn(false);
        setUserObj(null)
      }
        setInit(true);
    });
    
  }, []);

  const refreshUser = () => {
    const user = auth.currentUser;
    if (user) {
      setIsLoggedIn(true);
      setUserObj(user);
    } else {
      setIsLoggedIn(false);
      setUserObj(null);
    }
  };

  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [loginPage, setLoginPage] = useState(0);

  const openLoginPopup = () => {
    setLoginPage(0); // 로그인 페이지 상태 초기화
    setIsLoginVisible(true); // 로그인 팝업을 보이게 설정
  };

  const closeLoginPopup = () => {
    setLoginPage(0);
    setIsLoginVisible(false);
    window.location.reload();
  }

  const loginContainerClick = () => {
    setIsLoginVisible(false);
    window.location.reload();
  };

  return (
    <>
     {init ? (
      <>
      <ScrollToTop />
<Navigation userObj={userObj} refreshUser={refreshUser} isLoginVisible={isLoginVisible} setIsLoginVisible={setIsLoginVisible} openLoginPopup={openLoginPopup} closeLoginPopup={closeLoginPopup} loginContainerClick={loginContainerClick}/>

      {isLoggedIn ? (
    <Routes>
      <Route path='/' element={<Home />}/>
      {/*<Route path='/mypage' element={<MyPage userObj={userObj} refreshUser={refreshUser}/>}/>*/}
      <Route path='/mypage' element={<MyPage userObj={userObj}/>}/>
      <Route path='/template' element={<Template userObj={userObj}/>}/>
      <Route path='/translation' element={<Translation/>}/>
      <Route path='/premium' element={<Premium/>}/>
      <Route path="/portfolio" element={<Portfolio />}/>
      <Route path="/selection" element={<Selection userObj={userObj}/>}/>
      <Route path="/translationform" element={<TranslationForm/>}/>
      <Route path="/premiumform" element={<PremiumForm/>}/>
      <Route path="/templateform" element={<TemplateForm userObj={userObj}/>}/>
      <Route path="/templatedetail/:postId" element={<TemplateDetail userObj={userObj}/>}/>
      <Route path="/term" element={<Term />}/>
      <Route path="/personal" element={<Personal />}/>

    </Routes>
      ) : (
        <Routes>
        <Route path='/' element={<Home />}/>
        <Route element={<Login />}/>
        <Route element={<SignUp refreshUser={refreshUser}/>}/>
        <Route path='/template' element={<Template userObj={userObj} openLoginPopup={openLoginPopup}/>}/>
        <Route path='/translation' element={<Translation/>}/>
        <Route path='/premium' element={<Premium/>}/>
        <Route path="/portfolio" element={<Portfolio />}/>
        <Route path="/selection" element={<Selection userObj={userObj}/>}/>
        <Route path="/translationform" element={<TranslationForm/>}/>
        <Route path="/premiumform" element={<PremiumForm/>}/>
        <Route path="/term" element={<Term />}/>
        <Route path="/personal" element={<Personal />}/>
        </Routes>
      )
      }
    </>
     ) : (
      <>
      <div className="loading">
      <span></span> 
      <span></span>
      <span></span>  
      </div>
      <Navigation userObj={userObj} refreshUser={refreshUser}/>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route element={<Login />}/>
        <Route element={<SignUp refreshUser={refreshUser}/>}/>
        <Route path='/template' element={<Template />}/>
        <Route path='/translation' element={<Translation />}/>
        <Route path='/premium' element={<Premium />}/>
        <Route path="/portfolio" element={<Portfolio />}/>
        <Route path="/selection" element={<Selection />}/>
        <Route path="/translationform" element={<TranslationForm />}/>
        <Route path="/premiumform" element={<PremiumForm />}/>
        <Route path="/templateform" element={<TemplateForm />}/>
        </Routes>
      </>
    )}
    </>
  );
}

export default App;

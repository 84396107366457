import React, { useState, useEffect, useRef} from 'react';
import NavMenuHome from './NavMenuHome';
import footlogo from '../asset/footer_logo.svg';
import '../css/portfolio.css';
import PortfolioTitle from './components/PortfolioTitle';
import { Link } from 'react-router-dom';

function Portfolio() {
    const [isAVisible, setAVisible] = useState(false); //1
    const [isBVisible, setBVisible] = useState(false); //2
    const [isCVisible, setCVisible] = useState(false); //3
    const [isDVisible, setDVisible] = useState(false); //4
    const [isEVisible, setEVisible] = useState(false); //5
    const [isFVisible, setFVisible] = useState(false); //6
    const [isGVisible, setGVisible] = useState(false); //7
    const [isHVisible, setHVisible] = useState(false); //8
    const [isIVisible, setIVisible] = useState(false); //9
    const [isJVisible, setJVisible] = useState(false); //10
    const [isKVisible, setKVisible] = useState(false); //11
    const [isLVisible, setLVisible] = useState(false); //12
    const [isMVisible, setMVisible] = useState(false); //13
    const [isNVisible, setNVisible] = useState(false); //14
    const [isOVisible, setOVisible] = useState(false); //15
    const [isPVisible, setPVisible] = useState(false); //16
    const [isQVisible, setQVisible] = useState(false); //17


    const openAPopup = () => {
        setAVisible(true);
    };
    const closeAPopup = () => {
        setAVisible(false);
    };
    const aContainerClick = () => {
        setAVisible(false);
    };
      const aPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openBPopup = () => {
        setBVisible(true);
    };
    const closeBPopup = () => {
        setBVisible(false);
    };
    const bContainerClick = () => {
        setBVisible(false);
    };
      const bPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openCPopup = () => {
        setCVisible(true);
    };
    const closeCPopup = () => {
        setCVisible(false);
    };
    const cContainerClick = () => {
        setCVisible(false);
    };
      const cPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openDPopup = () => {
        setDVisible(true);
    };
    const closeDPopup = () => {
        setDVisible(false);
    };
    const dContainerClick = () => {
        setDVisible(false);
    };
      const dPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openEPopup = () => {
        setEVisible(true);
    };
    const closeEPopup = () => {
        setEVisible(false);
    };
    const eContainerClick = () => {
        setEVisible(false);
    };
      const ePopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openFPopup = () => {
        setFVisible(true);
    };
    const closeFPopup = () => {
        setFVisible(false);
    };
    const fContainerClick = () => {
        setFVisible(false);
    };
      const fPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openGPopup = () => {
        setGVisible(true);
    };
    const closeGPopup = () => {
        setGVisible(false);
    };
    const gContainerClick = () => {
        setGVisible(false);
    };
      const gPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openHPopup = () => {
        setHVisible(true);
    };
    const closeHPopup = () => {
        setHVisible(false);
    };
    const hContainerClick = () => {
        setHVisible(false);
    };
      const hPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openIPopup = () => {
        setIVisible(true);
    };
    const closeIPopup = () => {
        setIVisible(false);
    };
    const iContainerClick = () => {
        setIVisible(false);
    };
      const iPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openJPopup = () => {
        setJVisible(true);
    };
    const closeJPopup = () => {
        setJVisible(false);
    };
    const jContainerClick = () => {
        setJVisible(false);
    };
      const jPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openKPopup = () => {
        setKVisible(true);
    };
    const closeKPopup = () => {
        setKVisible(false);
    };
    const kContainerClick = () => {
        setKVisible(false);
    };
      const kPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openLPopup = () => {
        setLVisible(true);
    };
    const closeLPopup = () => {
        setLVisible(false);
    };
    const lContainerClick = () => {
        setLVisible(false);
    };
      const lPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openMPopup = () => {
        setMVisible(true);
    };
    const closeMPopup = () => {
        setMVisible(false);
    };
    const mContainerClick = () => {
        setMVisible(false);
    };
      const mPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openNPopup = () => {
        setNVisible(true);
    };
    const closeNPopup = () => {
        setNVisible(false);
    };
    const nContainerClick = () => {
        setNVisible(false);
    };
      const nPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openOPopup = () => {
        setOVisible(true);
    };
    const closeOPopup = () => {
        setOVisible(false);
    };
    const oContainerClick = () => {
        setOVisible(false);
    };
      const oPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openPPopup = () => {
        setPVisible(true);
    };
    const closePPopup = () => {
        setPVisible(false);
    };
    const pContainerClick = () => {
        setPVisible(false);
    };
      const pPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };

    const openQPopup = () => {
        setQVisible(true);
    };
    const closeQPopup = () => {
        setQVisible(false);
    };
    const qContainerClick = () => {
        setQVisible(false);
    };
      const qPopupClick = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 중단
    };


    const [isSticky, setSticky] = useState('off');
    const goRef = useRef(null); // 'header-middle' 요소에 대한 ref
    const backRef = useRef(null); // 'header-middle' 요소에 대한 ref
  
    useEffect(() => {
        const handleScroll = () => {
            const go = goRef.current;
            const back = backRef.current;
            
            if (go.getBoundingClientRect().top <= 0) {
                setSticky('on');
            } else if (back.getBoundingClientRect().top > 0) {
                setSticky('off');
            }
        };
  
        window.addEventListener('scroll', handleScroll);
  
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  


  return (
    <>
    <div ref={goRef}></div>

    <div className='moving-menu-emty'>
    <div className={`${isSticky === 'on' ? 'fixed-moving-menu' : 'moving-menu'}`}>
    <NavMenuHome/>
    </div>
    </div>

    <div ref={backRef}></div>

    <div className='portfolio-info'>
        <div className='portfolio-title'>
        <div className='portfolio-title-wrap'>
        <PortfolioTitle aniTxt={'달리페이지 포트폴리오'} />
        <div className='portfolio-title-a'></div>
        <div className='portfolio-title-b'>구매로 이어지는 상세페이지 저희가 만들어 드리겠습니다.</div>
        </div>
        </div>
    </div>

    <div className='portfolio-wrap'>
        <div className='port-five'>
        <div className='port-five-a' onClick={openQPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isQVisible ? 'hidden' : ''}`} onClick={qContainerClick}>
                <div className="port_popup" onClick={qPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeQPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                    <div className='q-pop-1'></div>
                    <div className='q-pop-2'></div>
                    <div className='q-pop-3'></div>
                    <div className='q-pop-4'></div>
                    <div className='q-pop-5'></div>
                    <div className='q-pop-6'></div>
                    <div className='q-pop-7'></div>
                    <div className='q-pop-8'></div>
                    <div className='q-pop-9'></div>
                    <div className='q-pop-10'></div>
                    <div className='q-pop-11'></div>
                    <div className='q-pop-12'></div>
                    <div className='q-pop-13'></div>
                
                </div>
                </div>
                </div>
            <div className='port-one-d' onClick={openDPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isDVisible ? 'hidden' : ''}`} onClick={dContainerClick}>
                <div className="port_popup" onClick={dPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeDPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                    <div className='d-pop-1'></div>
                    <div className='d-pop-2'></div>
                    <div className='d-pop-3'></div>
                    <div className='d-pop-4'></div>
                    <div className='d-pop-5'></div>
                    <div className='d-pop-6'></div>
                    <div className='d-pop-7'></div>
                    <div className='d-pop-8'></div>
                    <div className='d-pop-9'></div>
                    <div className='d-pop-10'></div>
                    <div className='d-pop-11'></div>
                    <div className='d-pop-12'></div>
                    <div className='d-pop-13'></div>
                    <div className='d-pop-14'></div>
                    <div className='d-pop-15'></div>
                    <div className='d-pop-16'></div>
                    <div className='d-pop-17'></div>
                    <div className='d-pop-18'></div>
                
                </div>
                </div>
                </div>
            <div className='port-one-b' onClick={openBPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isBVisible ? 'hidden' : ''}`} onClick={bContainerClick}>
                <div className="port_popup" onClick={bPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeBPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                    <div className='b-pop-1'></div>
                    <div className='b-pop-2'></div>
                    <div className='b-pop-3'></div>
                    <div className='b-pop-4'></div>
                    <div className='b-pop-5'></div>
                    <div className='b-pop-6'></div>
                    <div className='b-pop-7'></div>
                    <div className='b-pop-8'></div>
                    <div className='b-pop-9'></div>
                    <div className='b-pop-10'></div>
                    <div className='b-pop-11'></div>
                    <div className='b-pop-12'></div>
                    <div className='b-pop-13'></div>
                    <div className='b-pop-14'></div>
                
                </div>
                </div>
                </div>
            <div className='port-one-c' onClick={openCPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isCVisible ? 'hidden' : ''}`} onClick={cContainerClick}>
                <div className="port_popup" onClick={cPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeCPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                    <div className='c-pop-1'></div>
                    <div className='c-pop-2'></div>
                    <div className='c-pop-3'></div>
                    <div className='c-pop-4'></div>
                    <div className='c-pop-5'></div>
                    <div className='c-pop-6'></div>
                    <div className='c-pop-7'></div>
                    <div className='c-pop-8'></div>
                    <div className='c-pop-9'></div>
                    <div className='c-pop-10'></div>
                    <div className='c-pop-11'></div>
                    <div className='c-pop-12'></div>
                    <div className='c-pop-13'></div>
                    <div className='c-pop-14'></div>
                    <div className='c-pop-15'></div>
                    <div className='c-pop-16'></div>
                    <div className='c-pop-17'></div>
                    <div className='c-pop-18'></div>
                
                </div>
                </div>
                </div>
        </div>

        <div className='port-one'>
            <div className='port-one-a' onClick={openAPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isAVisible ? 'hidden' : ''}`} onClick={aContainerClick}>
                <div className="port_popup" onClick={aPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9, border:"1px solid black"}}></div>
                <div class="port_close-button" onClick={closeAPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                    <div className='a-pop-1'></div>
                    <div className='a-pop-2'></div>
                    <div className='a-pop-3'></div>
                    <div className='a-pop-4'></div>
                    <div className='a-pop-5'></div>
                    <div className='a-pop-6'></div>
                    <div className='a-pop-7'></div>
                    <div className='a-pop-8'></div>
                    <div className='a-pop-9'></div>
                    <div className='a-pop-10'></div>
                    <div className='a-pop-11'></div>
                    <div className='a-pop-12'></div>
                    <div className='a-pop-13'></div>
                    <div className='a-pop-14'></div>
                    <div className='a-pop-15'></div>
                    <div className='a-pop-16'></div>
                    <div className='a-pop-17'></div>
                    <div className='a-pop-18'></div>

                </div>
                </div>
                </div>
            <div className='port-two-a' onClick={openEPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isEVisible ? 'hidden' : ''}`} onClick={eContainerClick}>
                <div className="port_popup" onClick={ePopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeEPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                    <div className='e-pop-1'></div>
                    <div className='e-pop-2'></div>
                    <div className='e-pop-3'></div>
                    <div className='e-pop-4'></div>
                    <div className='e-pop-5'></div>
                    <div className='e-pop-6'></div>
                    <div className='e-pop-7'></div>
                    <div className='e-pop-8'></div>
                    <div className='e-pop-9'></div>
                    <div className='e-pop-10'></div>
                    <div className='e-pop-11'></div>
                    <div className='e-pop-12'></div>
                    <div className='e-pop-13'></div>
                    <div className='e-pop-14'></div>
                    <div className='e-pop-15'></div>
                    <div className='e-pop-16'></div>
                    <div className='e-pop-17'></div>
                    <div className='e-pop-18'></div>
                    <div className='e-pop-19'></div>
                    <div className='e-pop-20'></div>
                    <div className='e-pop-21'></div>
                    <div className='e-pop-22'></div>
                
                </div>
                </div>
                </div>
            <div className='port-two-b' onClick={openFPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isFVisible ? 'hidden' : ''}`} onClick={fContainerClick}>
                <div className="port_popup" onClick={fPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeFPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                    <div className='f-pop-1'></div>
                    <div className='f-pop-2'></div>
                    <div className='f-pop-3'></div>
                    <div className='f-pop-4'></div>
                    <div className='f-pop-5'></div>
                    <div className='f-pop-6'></div>
                    <div className='f-pop-7'></div>
                    <div className='f-pop-8'></div>
                    <div className='f-pop-9'></div>
                    <div className='f-pop-10'></div>
                    <div className='f-pop-11'></div>
                    <div className='f-pop-12'></div>
                    <div className='f-pop-13'></div>
                    <div className='f-pop-14'></div>
                    <div className='f-pop-15'></div>
                    <div className='f-pop-16'></div>
                    <div className='f-pop-17'></div>
                    <div className='f-pop-18'></div>
                    <div className='f-pop-19'></div>
                    <div className='f-pop-20'></div>
                
                </div>
                </div>
                </div>
            <div className='port-two-c' onClick={openGPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isGVisible ? 'hidden' : ''}`} onClick={gContainerClick}>
                <div className="port_popup" onClick={gPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeGPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                    <div className='g-pop-1'></div>
                    <div className='g-pop-2'></div>
                    <div className='g-pop-3'></div>
                    <div className='g-pop-4'></div>
                    <div className='g-pop-5'></div>
                    <div className='g-pop-6'></div>
                    <div className='g-pop-7'></div>
                    <div className='g-pop-8'></div>
                    <div className='g-pop-9'></div>
                    <div className='g-pop-10'></div>
                    <div className='g-pop-11'></div>
                    <div className='g-pop-12'></div>
                    <div className='g-pop-13'></div>
                    <div className='g-pop-14'></div>
                    <div className='g-pop-15'></div>
                    <div className='g-pop-16'></div>
                
                </div>
                </div>
                </div>
        </div>

        <div className='port-two'>
            <div className='port-two-d' onClick={openHPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isHVisible ? 'hidden' : ''}`} onClick={hContainerClick}>
                <div className="port_popup" onClick={hPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeHPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                    <div className='h-pop-1'></div>
                
                </div>
                </div>
                </div>
            <div className='port-three-a' onClick={openIPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isIVisible ? 'hidden' : ''}`} onClick={iContainerClick}>
                <div className="port_popup" onClick={iPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeIPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                <div className='i-pop-1'></div>
                
                </div>
                </div>
                </div>
            <div className='port-three-b' onClick={openJPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isJVisible ? 'hidden' : ''}`} onClick={jContainerClick}>
                <div className="port_popup" onClick={jPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeJPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                <div className='j-pop-1'></div>
                
                </div>
                </div>
                </div>
            <div className='port-three-c' onClick={openKPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isKVisible ? 'hidden' : ''}`} onClick={kContainerClick}>
                <div className="port_popup" onClick={kPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeKPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                <div className='k-pop-1'></div>
                
                </div>
                </div>
                </div>
        </div>

        <div className='port-three'>
            <div className='port-three-d' onClick={openLPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isLVisible ? 'hidden' : ''}`} onClick={lContainerClick}>
                <div className="port_popup" onClick={lPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeLPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                <div className='l-pop-1'></div>
                
                </div>
                </div>
                </div>
            <div className='port-four-a' onClick={openMPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isMVisible ? 'hidden' : ''}`} onClick={mContainerClick}>
                <div className="port_popup" onClick={mPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeMPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                <div className='m-pop-1'></div>
                
                </div>
                </div>
                </div>
            <div className='port-four-b' onClick={openNPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isNVisible ? 'hidden' : ''}`} onClick={nContainerClick}>
                <div className="port_popup" onClick={nPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeNPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                <div className='n-pop-1'></div>
                
                </div>
                </div>
                </div>
            <div className='port-four-c' onClick={openOPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isOVisible ? 'hidden' : ''}`} onClick={oContainerClick}>
                <div className="port_popup" onClick={oPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closeOPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                <div className='o-pop-1'></div>
                
                </div>
                </div>
                </div>
        </div>

        <div className='port-four'>
            <div className='port-four-d' onClick={openPPopup}></div>
                <div id="popupContainer" className={`popup-container ${!isPVisible ? 'hidden' : ''}`} onClick={pContainerClick}>
                <div className="port_popup" onClick={pPopupClick}>
                <div style={{display:'flex', position:"fixed"}}>
                <div style={{flex:9}}></div>
                <div class="port_close-button" onClick={closePPopup} style={{flex:0.8, textAlign:'right', marginLeft:"820px"}}>&times;</div>
                </div>
                <div>

                <div className='p-pop-1'></div>
                
                </div>
                </div>
                </div>
            <div className='port-four-e' ></div>
            <div className='port-four-e' ></div>
            <div className='port-four-e' ></div>
        </div>
    </div>

    <div className='portfolio-bottom'>
    
    </div>



    <div className="home-nine">
        <div className="home-footer">
                  <div className="footer-wrap">
          
                    <div className="footer-right">
                    <img src={footlogo}/>
                    </div>

                    <div className="footer-left">

                            <div className="footer-left-txt">

                                <div className="left-txt-top">
                                <Link to='/term'>
                                  이용약관
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
                                  <Link to='/personal'>
                                  개인정보취급방침
                                  </Link>
                                  &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                  개인정보의 수집 및 이용목적
                                </div>

                                <div className="left-txt-middle">
                                상호:(주)빅파이씨앤티 | 사업자 등록번호 : 169-81-00496 | 팩스번호 : 032-714-3850<br/>
                                  경기도 부천시 조마루로 385번길 122 삼보테크노타워 2702-3호 (주)빅파이씨앤티<br/>
                                  대표이사: 이중엽 | 통신판매 신고번호 : 제 2021-경기부천-1209호 | 개인정보보호책임자 : 김두영 | 메일: bigpie_art@jungdari.com<br/>
                                </div>

                                <div className="left-txt-bottom">
                                Copyrights2023ⓒBigpieC&T. All Rights Reserved
                                </div>

                            </div>

                </div>
                </div>
    </div>
    </div>
    </>
  );
}

export default Portfolio;